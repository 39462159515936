import React, { useState, useEffect, useRef } from "react";
import "../styles.css";
import * as Dialog from "@radix-ui/react-dialog";
import crossicon from "src/assets/cross-2.svg";
import { useAppContext } from "./LLMContext";

export interface DropdownllmProps {
  selectedModel: string;
  setSelectedModel: (model: string) => void;
  selectedCreator: string;
  setSelectedCreator: (value: string) => void;
  selectedLLM: string;
  setSelectedLLM: (value: string) => void;
  disabled: boolean;
}
const Dropdownllm: React.FC<DropdownllmProps> = ({ disabled }) => {
  const {
    selectedModel,
    setSelectedModel,
    selectedCreator,
    setSelectedCreator,
    selectedLLM,
    setSelectedLLM,
  } = useAppContext();
  //   const [selectedModel, setSelectedModel] = useState("LLM:");
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenCreator, setIsOpenCreator] = useState(false);
  const [isOpenModel, setIsOpenModel] = useState(false);

  const dropdownRef = useRef<HTMLDivElement>(null);
  const dropdownRef2 = useRef<HTMLDivElement>(null);
  const dropdownRef3 = useRef<HTMLDivElement>(null);

  //IMPORTANT TODO
  ///Make selectedCreator usestate & for LLM to combine two text (front-end purposes)
  //add interface for them too.

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      dropdownRef2.current &&
      // dropdownRef3.current &&
      // !dropdownRef3.current.contains(event.target as Node) &&
      !dropdownRef2.current.contains(event.target as Node) &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setIsOpen(false);
      setIsOpenCreator(false);
      setIsOpenModel(false);
    }
  };
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
    setIsOpenModel(false);
    setIsOpenCreator(false);
  };
  const toggleCreatorDropdown = () => {
    setIsOpenCreator(!isOpenCreator);
    setIsOpenModel(false);
  };
  const toggleModelDropdown = () => {
    setIsOpenModel(!isOpenModel);
    setIsOpenCreator(false);
  };
  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleLlmChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    switch (name) {
      case "selectedCreator":
        setSelectedCreator && setSelectedCreator(value);
        break;
      case "selectedModel":
        setSelectedModel && setSelectedModel(value);
        break;
      default:
        break;
    }
  };
  const [isOpen1, setIsOpen1] = useState(false);
  const handleDropdownClick = () => {
    setIsOpen1(true);
  };

  return (
    <>
      <div className="flex-div flex-div-between">
        <div className="dropdown-container" ref={dropdownRef}>
          <input
            className="dropbtn"
            type="button"
            onClick={disabled ? handleDropdownClick : toggleDropdown}
            value={!selectedLLM ? "OpenAI:gpt-4o-mini" : selectedLLM}
            // onChange={() =>
            //   setIsSelectedLLM(selectedCreator + ":" + selectedModel)
            // }
          />
          {/* creator  button*/}
          {isOpen && (
            <div className="dropdown-content-CC">
              <div
                className="llm-btn"
                ref={dropdownRef2}
                onClick={() => {
                  toggleCreatorDropdown();
                }}
                // onMouseEnter={() => {
                //   handleMouseEnterCreatorOpen();
                //   handleMouseEnterModelClose();
                // }}
              >
                <input
                  type="button"
                  name="selectedCreator"
                  value={selectedCreator}
                  onChange={handleLlmChange}
                />
                <svg
                  className="dropdown-svg"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 320 512"
                >
                  <path
                    fill="#ffffff"
                    d="M278.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L210.7 256 73.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z"
                  />
                </svg>
              </div>
              {/* Model button*/}
              <div
                className="llm-btn"
                ref={dropdownRef3}
                onClick={toggleModelDropdown}

                // onMouseEnter={() => {
                //   handleMouseEnterModelOpen();
                //   handleMouseEnterCreatorClose();
                // }}
              >
                <input
                  type="button"
                  name="selectedModel"
                  value={selectedModel}
                  onChange={handleLlmChange}
                />
                <svg
                  className="dropdown-svg"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 320 512"
                >
                  <path
                    fill="#ffffff"
                    d="M278.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L210.7 256 73.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z"
                  />
                </svg>
              </div>
            </div>
          )}
          {/* creator list*/}
          {isOpenCreator && (
            <div
              className="dropdown-content"
              ref={dropdownRef2}
              // onMouseEnter={handleMouseEnterCreatorOpen}
              // onMouseLeave={handleMouseEnterCreatorClose}
            >
              <input
                type="button"
                value="OpenAI"
                onClick={(e) => {
                  setSelectedCreator("OpenAI");
                  setSelectedModel("Model");
                  toggleCreatorDropdown();
                  // toggleDropdown();
                }}
              />
              <input
                type="button"
                value="Anthropic"
                onClick={(e) => {
                  setSelectedCreator("Anthropic");
                  setSelectedModel("Model");
                  toggleCreatorDropdown();
                  // toggleDropdown();
                }}
              />
            </div>
          )}
          {/*open Anthropic models if the selected creator is Anthropic*/}
          {selectedCreator === "Anthropic"
            ? isOpenModel && (
                <div
                  className="dropdown-content"
                  // onMouseEnter={handleMouseEnterModelOpen}
                  // onMouseLeave={handleMouseEnterModelClose}
                  ref={dropdownRef3}
                >
                  <input
                    type="button"
                    value="claude-3-5-sonnet-20240620"
                    onClick={(e) => {
                      setSelectedModel("claude-3-5-sonnet-20240620");
                      toggleModelDropdown();
                      toggleDropdown();
                    }}
                  />
                  <input
                    type="button"
                    value="claude-3-opus-20240229"
                    onClick={(e) => {
                      setSelectedModel("claude-3-opus-20240229");
                      toggleModelDropdown();
                      toggleDropdown();
                    }}
                  />
                  <input
                    type="button"
                    value="claude-3-haiku-20240307"
                    onClick={(e) => {
                      setSelectedModel("claude-3-haiku-20240307");
                      toggleModelDropdown();
                      toggleDropdown();
                    }}
                  />
                </div>
              )
            : ""}
          {/* open OpenAI models if the selected creator is OpenAI*/}
          {selectedCreator === "OpenAI"
            ? isOpenModel && (
                <div
                  className="dropdown-content"
                  // onMouseEnter={handleMouseEnterModelOpen}
                  // onMouseLeave={handleMouseEnterModelClose}
                  ref={dropdownRef3}
                >
                  <input
                    type="button"
                    value="gpt-3.5-turbo-0125"
                    onClick={(e) => {
                      setSelectedModel("gpt-3.5-turbo-0125");
                      toggleModelDropdown();
                      toggleDropdown();
                    }}
                  />
                  <input
                    type="button"
                    value="gpt-4o"
                    onClick={(e) => {
                      setSelectedModel("gpt-4o");
                      toggleModelDropdown();
                      toggleDropdown();
                    }}
                  />
                  <input
                    type="button"
                    value="gpt-4o-mini"
                    onClick={(e) => {
                      setSelectedModel("gpt-4o-mini");
                      toggleModelDropdown();
                      toggleDropdown();
                    }}
                  />
                  <input
                    type="button"
                    value="gpt-4"
                    onClick={(e) => {
                      setSelectedModel("gpt-4");
                      toggleModelDropdown();
                      toggleDropdown();
                    }}
                  />
                  <input
                    type="button"
                    value="gpt-4-turbo"
                    onClick={(e) => {
                      setSelectedModel("gpt-4-turbo");
                      toggleModelDropdown();
                      toggleDropdown();
                    }}
                  />
                  {/* <input
                  type="button"
                  value="o1-mini"
                  onClick={(e) => {
                    setSelectedModel("o1-mini");
                    toggleModelDropdown();
                    toggleDropdown();
                  }}
                />
                <input
                  type="button"
                  value="o1-preview"
                  onClick={(e) => {
                    setSelectedModel("o1-preview");
                    toggleModelDropdown();
                    toggleDropdown();
                  }}
                /> */}
                </div>
              )
            : ""}
          {}
        </div>
      </div>

      <Dialog.Root
        open={isOpen1}
        onOpenChange={(open) => {
          // if (!open) {
          //   handleDropdownClick();
          // }
          setIsOpen1(open);
        }}
      >
        <Dialog.Trigger asChild></Dialog.Trigger>
        <Dialog.Portal>
          <div className="sideBar-subs-panel">
            <div className="overlay"></div>
            <Dialog.Overlay className="DialogOverlay" />
            <Dialog.Content className="DialogContent DialogCustomWidth-signup">
              <Dialog.Title className="DialogTitle"></Dialog.Title>
              <Dialog.Description className="DialogDescription"></Dialog.Description>

              <div className="subscription-panel">
                <h2>Unable to change Model</h2>
                <p className="description">
                  You cannot change the model while in an active chat. To select
                  a different model, please start a new chat.
                </p>
              </div>

              <div
                style={{
                  display: "flex",
                  marginTop: 25,
                  justifyContent: "flex-end",
                }}
              >
                <Dialog.Close asChild></Dialog.Close>
              </div>
              <Dialog.Close asChild>
                <button className="IconButton" aria-label="Close">
                  <img
                    src={crossicon}
                    alt="icon"
                    style={{
                      width: "20px",
                      height: "20px",

                      filter: "invert(1)",
                    }}
                  />
                </button>
              </Dialog.Close>
            </Dialog.Content>
          </div>
        </Dialog.Portal>
      </Dialog.Root>
    </>
  );
};
export default Dropdownllm;
