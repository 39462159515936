import React from "react";
import logo from "./logo.svg";
import "./App.css";

import "./styles.css";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { redirect } from "react-router-dom";
import ChatScreenWindow from "./components/ChatScreenWindow";

import { InputTextProps } from "./components/SendMessage";
import WelcomeScreen from "./screens/WelcomeScreen";
import Signup from "./components/Signup";
import Login from "./components/Login";
import ResetPassword from "./components/ResetPassword";
import { useAuth } from "./components/AuthContext";

const App: React.FC<InputTextProps> = ({
  selectedModel,
  selectedCreator,
  setSelectedModel,
  selectedChatId,
  chats,
  updateChatMessages,
}) => {
  const { session, setSession } = useAuth();
  const user_id = session?.user?.user_metadata.user_id;

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Navigate to="/welcome" replace />} />
        <Route path="/welcome" element={<WelcomeScreen />} />

        <Route
          path="/chat"
          element={
            <ChatScreenWindow
              selectedChatId={selectedChatId}
              chats={chats}
              updateChatMessages={updateChatMessages}
              selectedCreator={selectedCreator}
              selectedModel={selectedModel}
              setSelectedModel={setSelectedModel}
            />
          }
        />

        <Route path="/signup" element={<Signup />} />
        <Route path="/login" element={<Login />} />
        <Route path="/reset-password" element={<ResetPassword />} />
      </Routes>
    </Router>
  );
};

export default App;
