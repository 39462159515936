// src/components/Sidebar.tsx
import React, { useEffect, useRef, useState } from "react";
import Button from "./Button";
import { ChatHistory, Message } from "./SendMessage";
// import 'src/chatwindow.css';
import writingImage from "src/assets/writing.png";
import settingImage from "src/assets/settings.png";
import logoutImage from "src/assets/logout.png";
import starImage from "src/assets/star.png";
import trashcan from "src/assets/trash-can.svg";
import { useNavigate } from "react-router-dom";
import * as Popover from "@radix-ui/react-popover";
import "src/morebutton.css";
// import { useSession } from "./SessionProvider";
import axios from "axios";
import * as Dialog from "@radix-ui/react-dialog";
import SubscriptionPanel, {
  BuyCreditsPanel,
} from "../components/SubscriptionPanel";
import crossicon from "src/assets/cross-2.svg";
import "src/modal.css";
import { useAuth } from "./AuthContext";
import { supabase } from "./SupabaseClient";
import SubsPanel from "../components/SubscriptionPanel";
import { useAppContext } from "./LLMContext";
import { Trash } from "lucide-react";
import MyBilling from "../components/MyBilling";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const urlEnv = process.env.REACT_APP_SERVER_ACCESS;

export function Image({ source, alternate }) {
  return <img className="image-size" src={source} alt={alternate} />;
}

export interface SidebarProps {
  chats: {
    // thread_id: number;
    id: number;
    title: string;
  }[];
  activeChatId: number | null;
  startNewChat: () => void;
  loadChat: (id: number) => void;
  deleteChat: (id: number) => void;

  isNewUser?: boolean;
  setIsNewUser?: React.Dispatch<React.SetStateAction<boolean>>;
  userSubstype: number;

  openModalPromo?: boolean;
  setOpenModalPromo?: React.Dispatch<React.SetStateAction<boolean>>;
}

interface Chat {
  id: number;
  title: string;
}

const Sidebar: React.FC<SidebarProps> = ({
  chats, // = [{ id: 1, title: "Chat 1" }],

  activeChatId,
  startNewChat,
  loadChat,
  deleteChat,
  isNewUser,
  setIsNewUser,
  userSubstype,
  openModalPromo,
  setOpenModalPromo,
}) => {
  const {
    selectedModel,
    setSelectedModel,
    selectedCreator,
    setSelectedCreator,
    selectedLLM,
    setSelectedLLM,
  } = useAppContext();
  const { session, setSession } = useAuth();
  const user_id = session?.user?.user_metadata.user_id;

  const [isModalOpen1, setModalOpen1] = useState(false);
  const [chatToDelete, setChatToDelete] = useState<number>(null);

  // const remainingMaxTokensApplicable = sessionStorage.getItem(
  //   "maxTokensApplicable"
  // );

  let model_id;
  useEffect(() => {
    switch (selectedModel) {
      // case "gpt-3.5-turbo":
      //   model_id = 5;
      //   break;
      case "gpt-4o":
        model_id = 2;
        break;
      case "gpt-4-turbo":
        model_id = 3;
        break;
      case "gpt-4":
        model_id = 4;
        break;
      case "claude-3-5-sonnet-20240620":
        model_id = 8;
        break;
      case "gpt-3.5-turbo-0125":
        model_id = 9;
        break;
      case "gpt-4o-mini":
        model_id = 11;
        break;
      case "claude-3-opus-20240229":
        model_id = 13;
        break;
      case "claude-3-haiku-20240307":
        model_id = 14;
        break;
      default:
        model_id = 9;
        break;
    }
  }, []);

  // if (activeChatId === null) {
  //   activeChatId = chats.length-1;
  // }

  const navigate = useNavigate();

  const handleLogout = async () => {
    const { error } = await supabase.auth.signOut();
    if (!error) {
      setSession(null);
      // localStorage.removeItem("supabaseSession");
      // sessionStorage.removeItem("user_id");
      navigate("/login");
      localStorage.removeItem("isSub");
    }
  };
  const hasCreatedInitialChat = useRef(false);
  useEffect(() => {
    if (chats.length > 0 && !hasCreatedInitialChat.current) {
      // Automatically load the last chat or whichever chat you want
      loadChat(chats[chats.length - 1].id);
      hasCreatedInitialChat.current = true;
      //console.log("loaded");
    }
  }, [chats, activeChatId, loadChat]);

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await axios.post(`${urlEnv}fetch-credits-history`, {
        user_id: user_id,
      });
      setData(response.data);
    } catch (err) {
      setError(err.message || "An error occurred");
    } finally {
      setLoading(false);
    }
  };

  const formatDate = (dateString: string): string => {
    const date = new Date(dateString);

    // Check if the date is valid
    if (isNaN(date.getTime())) {
      return "Invalid Date";
    }

    // Options for formatting the date
    const options: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "long",
      day: "numeric",
    };

    // Return the formatted date
    return date.toLocaleDateString("en-US", options);
  };

  return (
    <div className="sidebar">
      <button className="new-chat-button left-margin-t" onClick={startNewChat}>
        <Image source={writingImage} alternate={"writingImage"} />
      </button>
      <div className="chat-history">
        {chats.map((chat) => (
          <div
            key={chat.id}
            className={`sidebar-chats ${
              chat.id === activeChatId ? "active" : ""
            }`}
          >
            <div
              className={`chat-history-item ${
                chat.id === activeChatId ? "active" : ""
              }`}
              onClick={() => {
                loadChat(chat.id);
              }}
            >
              {chat.title}
            </div>

            <Dialog.Root open={isModalOpen1} onOpenChange={setModalOpen1}>
              <Dialog.Trigger asChild>
                <button
                  //more-button chat-history-item
                  className={`delete-thread-button ${
                    chat.id === activeChatId ? "active" : ""
                  } `}
                  aria-label="Update dimensions"
                  onClick={() => {
                    setChatToDelete(chat.id);
                  }}
                >
                  <img
                    //image-button-size
                    className="delete-thread-button-image"
                    src={trashcan}
                    alt={"trashcan"}
                  />
                </button>
              </Dialog.Trigger>
              <Dialog.Portal>
                <div className="overlay"></div>
                <Dialog.Overlay className="DialogOverlay" />
                <Dialog.Content className="DialogContent DialogCustomWidth-signup">
                  <Dialog.Title className="DialogTitle"></Dialog.Title>
                  <Dialog.Description className="DialogDescription"></Dialog.Description>

                  <div className="subscription-panel">
                    <h2>This will delete the chat conversation</h2>
                    <p className="description">Are you sure to delete ?</p>
                  </div>
                  <div className="modal-confirmation">
                    <Dialog.Close asChild>
                      <button className="no-button" aria-label="Close">
                        No
                      </button>
                    </Dialog.Close>
                    <button
                      onClick={() => {
                        const isDeleting: any = deleteChat(chatToDelete);
                        if (isDeleting) {
                          setModalOpen1(false);
                        }
                      }}
                      className="yes-button"
                      aria-label="Close"
                    >
                      Yes
                    </button>
                  </div>

                  <Dialog.Close asChild>
                    <button className="IconButton" aria-label="Close">
                      <img
                        src={crossicon}
                        alt="icon"
                        style={{
                          width: "20px",
                          height: "20px",
                          filter: "invert(1)",
                        }}
                      />
                    </button>
                  </Dialog.Close>
                </Dialog.Content>
              </Dialog.Portal>
            </Dialog.Root>
          </div>
        ))}
      </div>
      {session ? (
        <Dialog.Root open={openModalPromo} onOpenChange={setOpenModalPromo}>
          <Dialog.Trigger asChild>
            <button
              className="menu-footer-content green-color"
              onClick={fetchData}
            >
              <Image source={starImage} alternate={"starImage"} />
              Billing
            </button>
          </Dialog.Trigger>

          <Dialog.Portal>
            <div className="sideBar-subs-panel">
              <div className="overlay"></div>
              <Dialog.Overlay className="DialogOverlay" />
              <Dialog.Content className="DialogContent DialogCustomWidth">
                <Dialog.Title className="DialogTitle"></Dialog.Title>
                <Dialog.Description className="DialogDescription"></Dialog.Description>
                <Elements stripe={stripePromise}>
                  <MyBilling
                    data={data}
                    loading={loading}
                    openModalPromo={openModalPromo}
                    setOpenModalPromo={setOpenModalPromo}
                    isNewUser={isNewUser}
                    userSubstype={userSubstype}
                  />
                </Elements>

                <div
                  style={{
                    display: "flex",
                    marginTop: 25,
                    justifyContent: "flex-end",
                  }}
                >
                  <Dialog.Close asChild></Dialog.Close>
                </div>
                <Dialog.Close asChild>
                  <button className="IconButton" aria-label="Close">
                    <img
                      src={crossicon}
                      alt="icon"
                      style={{
                        width: "20px",
                        height: "20px",

                        filter: "invert(1)",
                      }}
                    />
                  </button>
                </Dialog.Close>
              </Dialog.Content>
            </div>
          </Dialog.Portal>
        </Dialog.Root>
      ) : (
        <Dialog.Root open={openModalPromo} onOpenChange={setOpenModalPromo}>
          <Dialog.Trigger asChild></Dialog.Trigger>

          <Dialog.Portal>
            <div className="sideBar-subs-panel">
              <div className="overlay"></div>
              <Dialog.Overlay className="DialogOverlay" />
              <Dialog.Content className="DialogContent DialogCustomWidth">
                <Dialog.Title className="DialogTitle"></Dialog.Title>
                <Dialog.Description className="DialogDescription"></Dialog.Description>

                <MyBilling
                  data={data}
                  loading={loading}
                  openModalPromo={openModalPromo}
                  setOpenModalPromo={setOpenModalPromo}
                  isNewUser={isNewUser}
                  userSubstype={userSubstype}
                />

                <div
                  style={{
                    display: "flex",
                    marginTop: 25,
                    justifyContent: "flex-end",
                  }}
                >
                  <Dialog.Close asChild></Dialog.Close>
                </div>
                <Dialog.Close asChild>
                  <button className="IconButton" aria-label="Close">
                    <img
                      src={crossicon}
                      alt="icon"
                      style={{
                        width: "20px",
                        height: "20px",

                        filter: "invert(1)",
                      }}
                    />
                  </button>
                </Dialog.Close>
              </Dialog.Content>
            </div>
          </Dialog.Portal>
        </Dialog.Root>
      )}

      {/* 
      <Dialog.Root open={openModalPromo} onOpenChange={setOpenModalPromo}>
        <Dialog.Trigger asChild>
       
        </Dialog.Trigger>

        <Dialog.Portal>
          <div className="sideBar-subs-panel">
            <div className="overlay"></div>
            <Dialog.Overlay className="DialogOverlay" />
            <Dialog.Content className="DialogContent DialogCustomWidth">
              <Dialog.Title className="DialogTitle"></Dialog.Title>
              <Dialog.Description className="DialogDescription"></Dialog.Description>

              <BuyCreditsPanel isNewUser={isNewUser} />
              <MyBilling
                data={data}
                loading={loading}
                openModalPromo={openModalPromo}
                setOpenModalPromo={setOpenModalPromo}
                isNewUser={isNewUser}
                userSubstype={userSubstype}
              />

              <div
                style={{
                  display: "flex",
                  marginTop: 25,
                  justifyContent: "flex-end",
                }}
              >
                <Dialog.Close asChild></Dialog.Close>
              </div>
              <Dialog.Close asChild>
                <button className="IconButton" aria-label="Close">
                  <img
                    src={crossicon}
                    alt="icon"
                    style={{
                      width: "20px",
                      height: "20px",

                      filter: "invert(1)",
                    }}
                  />
                </button>
              </Dialog.Close>
            </Dialog.Content>
          </div>
        </Dialog.Portal>
      </Dialog.Root>
  
      <Dialog.Root>
        {userSubstype ? (
          <button className="menu-footer-content green-color" disabled>
            <Image source={starImage} alternate={"starImage"} />
            Subscribed to Premium version
          </button>
        ) : (
          <Dialog.Trigger asChild>
            <button className="menu-footer-content green-color">
              <Image source={starImage} alternate={"starImage"} />
              Upgrade to Premium version
            </button>
          </Dialog.Trigger>
        )}

        <Dialog.Portal>
          <div className="sideBar-subs-panel">
            <div className="overlay"></div>
            <Dialog.Overlay className="DialogOverlay" />
            <Dialog.Content className="DialogContent DialogCustomWidth">
              <Dialog.Title className="DialogTitle"></Dialog.Title>
              <Dialog.Description className="DialogDescription"></Dialog.Description>

              <SubscriptionPanel isNewUser={isNewUser} />

              <div
                style={{
                  display: "flex",
                  marginTop: 25,
                  justifyContent: "flex-end",
                }}
              >
                <Dialog.Close asChild></Dialog.Close>
              </div>
              <Dialog.Close asChild>
                <button className="IconButton" aria-label="Close">
                  <img
                    src={crossicon}
                    alt="icon"
                    style={{
                      width: "20px",
                      height: "20px",

                      filter: "invert(1)",
                    }}
                  />
                </button>
              </Dialog.Close>
            </Dialog.Content>
          </div>
        </Dialog.Portal>
      </Dialog.Root> */}

      {session && (
        <button className="menu-footer-content" onClick={handleLogout}>
          <Image source={logoutImage} alternate={"logoutImage"} />
          Logout
        </button>
      )}
    </div>
  );
};

export default Sidebar;
