import React, { useEffect, useState } from "react";
import * as Tabs from "@radix-ui/react-tabs";
import "../billingstyle.css";
import * as Dialog from "@radix-ui/react-dialog";
import SubscriptionPanel, {
  AutoRechargeSettings,
  BuyCreditsPanel,
  CustomTopUp,
} from "./SubscriptionPanel";
import crossicon from "src/assets/cross-2.svg";
import { useAuth } from "./AuthContext";
import {
  useStripe,
  useElements,
  CardElement,
  Elements,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import axios from "axios";
import Switch from "@mui/material/Switch";

interface MyBillProps {
  data: any;
  loading: boolean;
  openModalPromo?: boolean;
  setOpenModalPromo?: React.Dispatch<React.SetStateAction<boolean>>;
  isNewUser?: boolean;
  userSubstype?: number;
}

const MyBilling: React.FC<MyBillProps> = ({
  data,
  loading,
  openModalPromo,
  setOpenModalPromo,
  isNewUser,
  userSubstype,
}) => {
  const { session, setSession } = useAuth();
  const user_id = session?.user?.user_metadata.user_id;
  const [isSubscribed, setIsSubscribed] = useState(false);
  const user_email = session?.user?.email;
  const [tabValue, setTabValue] = useState("total");
  const [topUpValue, setTopUpValue] = useState("lifetime");
  const [openCustomTopUp, setOpenCustomTopUp] = useState(false);
  const [openAutoRecharge, setOpenAutoRecharge] = useState(false);
  const [autoData, setAutoData] = useState({
    threshold_amount: "",
    recharge_amount: "",
    active: "false",
  });
  const minAmount = 5;
  const maxAmount = 900;
  const [thresholdAmount, setThresholdAmount] = useState<string | "">(
    String(minAmount)
  );
  const [rechargeAmount, setRechargeAmount] = useState<string | "">("100");
  const [paymentMethodId, setPaymentMethodId] = useState("");
  const [paymentMethod, setPaymentMethod] = useState<any>(null);
  const [autoRecharge, setAutoRecharge] = useState(false);

  const [errorMessage, setErrorMessage] = useState<string>("");
  const [errorMessage1, setErrorMessage1] = useState<string>("");
  const [isFocused, setIsFocused] = useState(false);
  const [isFocused1, setIsFocused1] = useState(false);
  const [isCardValid, setIsCardValid] = useState(false);
  const [loadingCard, setIsLoadingCard] = useState(false);
  const [loadingCallFunc, setIsLoadingCallFunc] = useState(false);
  const [loadingSaveAutoRecharge, setIsloadingSaveAutoRecharge] =
    useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen1, setIsOpen1] = useState(false);

  const handleThresholdChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setThresholdAmount(e.target.value);
    validateAmountThreshold(e.target.value);
  };

  const handleRechargeAmountChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRechargeAmount(e.target.value);
    validateAmountRecharge(e.target.value);
  };

  const validateAmountThreshold = (value: string): void => {
    let numericValue = parseFloat(value);
    //console.log("numericValue", numericValue);

    if (
      isNaN(numericValue) ||
      numericValue < minAmount ||
      numericValue > maxAmount
    ) {
      setErrorMessage(
        `Please enter an amount between $${minAmount} and $${maxAmount}.`
      );
    } else {
      setErrorMessage("");
    }
  };

  const validateAmountRecharge = (value: string): void => {
    let numericValue = parseFloat(value);
    console.log("validateAmountRecharge", numericValue);

    if (
      isNaN(numericValue) ||
      numericValue < minAmount ||
      numericValue > maxAmount
    ) {
      setErrorMessage1(
        `Please enter an amount between $${minAmount} and $${maxAmount}.`
      );
    } else {
      setErrorMessage1("");
    }
  };

  const handleCardChange = (event: any) => {
    setIsCardValid(event.complete); // `event.complete` is true if the card info is valid
  };

  const handleSavePaymentMethod = async () => {
    const urlEnv = process.env.REACT_APP_SERVER_ACCESS;
    setIsLoadingCard(true);
    const response = await axios.post(`${urlEnv}create-setup-intent`, {
      user_id: user_id,
      email: user_email,
    });

    if (response.data.clientSecret) {
      console.log("handleSavePaymentMethod", response.data.clientSecret);
    }

    if (!stripe || !elements) return;

    const result = await stripe.confirmCardSetup(response.data.clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
      },
    });

    if (result.error) {
      console.error(result.error.message);
    } else {
      console.log("resultresultresultresult", result);

      const payment_method = result.setupIntent?.payment_method;
      console.log("Payment method saved!", result.setupIntent.payment_method);

      if (typeof payment_method === "string") {
        const response = await axios.post(`${urlEnv}save-payment-method`, {
          userId: user_id, // Pass the user ID
          payment_method, // Pass the payment method ID
        });

        setPaymentMethodId(payment_method);
      } else {
        console.error("Payment method ID is not available:", payment_method);
      }
    }
    setIsLoadingCard(false);
  };

  const fetchPaymentMethod = async () => {
    const urlEnv = process.env.REACT_APP_SERVER_ACCESS;
    if (paymentMethodId) {
      try {
        // Call your API to fetch the payment method details
        const response = await axios.get(`${urlEnv}get-payment-method`, {
          params: {
            existingPaymentMethodId: paymentMethodId,
            user_id: user_id,
          },
        });
        console.log(
          "response.dataasdasdasdqweqwdasad",
          response.data.paymentMethod.customer
        );
        // Check if payment method data is returned and set it to state
        if (response.data && response.data.paymentMethod) {
          console.log(
            "response.data.paymentMethod",
            response.data.paymentMethod
          );
          setPaymentMethod(response.data.paymentMethod);
        }
      } catch (error) {
        console.error("Error fetching payment method:", error);
      }
    }
  };

  const fetchPaymentMethodFromDB = async () => {
    const urlEnv = process.env.REACT_APP_SERVER_ACCESS;
    try {
      const response = await axios.get(`${urlEnv}get-payment-method-from-db`, {
        params: {
          user_id: user_id,
        },
      });

      if (response.data) {
        console.log("fetchPaymentMethodFromDB", response.data);

        setPaymentMethodId(response.data);
      }
    } catch (error) {
      // console.error("Error fetching payment method:", error);
    }
  };

  const handleSave = async () => {
    console.log("Auto Recharge Settings Saved:", {
      autoRecharge,
      thresholdAmount,
      rechargeAmount,
    });
    const urlEnv = process.env.REACT_APP_SERVER_ACCESS;

    setIsloadingSaveAutoRecharge(true);
    try {
      if (!errorMessage || !errorMessage1) {
        const response = await axios.post(`${urlEnv}save-auto-recharge`, {
          threshold_amount: thresholdAmount,
          recharge_amount: rechargeAmount,
          active: autoRecharge,
          user_id: user_id,
        });

        if (response) {
          console.log("response auto recharge:", response.data);
          await callFunc();
          setOpenAutoRecharge(false);
        }
      }
    } catch (error) {
      console.error("handleSave error:", error);
    }
    setIsloadingSaveAutoRecharge(false);
  };

  useEffect(() => {
    fetchPaymentMethod();
  }, [paymentMethodId]);

  useEffect(() => {
    if (!paymentMethodId) {
      fetchPaymentMethodFromDB();
    }
  }, []);

  const callFunc = async () => {
    console.log("callFunccallFunc");
    setIsLoadingCallFunc(true);

    const urlEnv = process.env.REACT_APP_SERVER_ACCESS;
    let userAutoRechargeData;
    try {
      const response = await axios.get(`${urlEnv}fetch-auto-recharge`, {
        params: { user_id: user_id },
      });

      if (response) {
        console.log("response.data fetchAutoRecharge:", response.data);
        userAutoRechargeData = response.data;
      } else {
        return null;
      }
    } catch (error) {
      // console.error("fetchAutoRecharge: ", error);
    }
    if (userAutoRechargeData) {
      console.log("userAutoRechargeDataasdasdsa1", userAutoRechargeData);
      if (userAutoRechargeData.active === "true") {
        setAutoData({
          threshold_amount: userAutoRechargeData.threshold_amount,
          recharge_amount: userAutoRechargeData.recharge_amount,
          active: "ON",
        });
      } else if (userAutoRechargeData.active === "false") {
        setAutoData({
          threshold_amount: userAutoRechargeData.threshold_amount,
          recharge_amount: userAutoRechargeData.recharge_amount,
          active: "OFF",
        });
      }
    }
  };

  useEffect(() => {
    callFunc();
  }, []);

  useEffect(() => {
    autoData.active === "ON" ? setAutoRecharge(true) : setAutoRecharge(false);
    setRechargeAmount(autoData.recharge_amount);
    setThresholdAmount(autoData.threshold_amount);
    setIsLoadingCallFunc(false);
  }, [autoData]);

  const [buyCreditsValue, setBuyCreditsValue] = useState(() => {
    if (isNewUser || !session) {
      return "payAsYouGo";
    } else if (!isNewUser) {
      return "monthlySubscription";
    }
  });

  const formatDate = (dateString: string): string => {
    const date = new Date(dateString);

    // Check if the date is valid
    if (isNaN(date.getTime())) {
      return "Invalid Date";
    }

    // Options for formatting the date
    const options: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "long",
      day: "numeric",
    };

    // Return the formatted date
    return date.toLocaleDateString("en-US", options);
  };

  const [loadingCancelSubs, setLoadingCancelSubs] = useState(false);
  const cancelSubsMonthly = async () => {
    setLoadingCancelSubs(true);
    const urlEnv = process.env.REACT_APP_SERVER_ACCESS;
    try {
      const response = await axios.get(`${urlEnv}cancel-subscription`, {
        params: { user_id },
      });
      if (response.data.message) {
        console.log("responsedatamessage123", response.data.message);
        setIsOpen(false);
        setIsOpen1(true);
      }
    } catch (error) {
      console.error("internal error cancelation of monthly:", error);
    }
    setLoadingCancelSubs(false);
  };

  const fetchSubStatus = async () => {
    const urlEnv = process.env.REACT_APP_SERVER_ACCESS;
    try {
      const response = await axios.get(`${urlEnv}get-sub-status`, {
        params: {
          user_id,
        },
      });
      if (response.data) {
        console.log("fetchSubStatus", response.data.status);
        setIsSubscribed(response.data.status);
        localStorage.setItem("isSub", response.data.status);
      }
    } catch (error) {
      console.error("errorfetchSubStatus:", error);
    }
  };

  useEffect(() => {
    fetchSubStatus();
  }, []);

  return (
    <div className="billing-container">
      <h1 className="credit-balance">Overview</h1>
      <Tabs.Root
        defaultValue="total"
        value={tabValue}
        onValueChange={setTabValue}
      >
        <Tabs.List className="tabs-list">
          <Tabs.Trigger
            value="total"
            className={tabValue === "total" ? "tab-active" : ""}
          >
            Total
          </Tabs.Trigger>
          <Tabs.Trigger
            value="subscription"
            className={tabValue === "subscription" ? "tab-active" : ""}
          >
            Subscription
          </Tabs.Trigger>
          <Tabs.Trigger
            value="topup"
            className={tabValue === "topup" ? "tab-active" : ""}
          >
            Top up
          </Tabs.Trigger>
        </Tabs.List>
        {/* Overview Panel */}
        <Tabs.Content value="total" className="tab-content">
          <h1 className="credit-balance2">Credits</h1>
          {loading && (
            <div className="subscription-panel">
              <div className="gridMysubsTotal">
                <div className="subscription-item">
                  <h3>Used Credits</h3>
                  <p>Loading...</p>
                </div>
                <div className="subscription-item">
                  <h3>Remaining Credits</h3>
                  <p>Loading...</p>
                </div>
                {/* <div className="subscription-item">
                  <h3>Duration Period: </h3>
                  <p>Loading...</p>
                </div>
                <div className="subscription-item">
                  <h3>Price</h3>
                  <p>Loading...</p>
                </div> */}
              </div>
            </div>
          )}
          {!loading && data && (
            <div className="subscription-panel">
              <div className="gridMysubsTotal">
                <div className="subscription-item">
                  <h3>Used Credits</h3>
                  <p>
                    {Number(data.totalUsedCredits.toFixed(4)).toLocaleString()}
                  </p>
                </div>
                <div className="subscription-item">
                  <h3>Remaining Credits</h3>
                  <p>{Number(data.totalCredits.toFixed(0)).toLocaleString()}</p>
                </div>
                {/* <div className="subscription-item">
                  <h3>Duration Period:</h3>
                  <p>
                    From:{" "}
                    {formatDate(
                      new Date(data.monthlyCredits.start_date).toLocaleString()
                    )}
                  </p>
                  <p>
                    To:{" "}
                    {formatDate(
                      new Date(data.monthlyCredits.expiry_date).toLocaleString()
                    )}
                  </p>
                </div>
                <div className="subscription-item">
                  <h3>Price</h3>
                  <p>${(data.monthlyCredits.price).toFixed(2)}</p>
                </div> */}
              </div>
            </div>
          )}
        </Tabs.Content>

        <Tabs.Content value="subscription" className="tab-content">
          <h1 className="credit-balance2">My Subscription</h1>
          {loading && (
            <div className="subscription-panel">
              <div className="gridMysubs">
                <div className="subscription-item">
                  <h3>Used Credits</h3>
                  <p>Loading...</p>
                </div>
                <div className="subscription-item">
                  <h3>Remaining Credits</h3>
                  <p>Loading...</p>
                </div>
                <div className="subscription-item">
                  <h3>Duration Period: </h3>
                  <p>Loading...</p>
                </div>
                <div className="subscription-item">
                  <h3>Price</h3>
                  <p>Loading...</p>
                </div>
              </div>
            </div>
          )}
          {data && (
            <div className="subscription-panel">
              <div className="gridMysubs">
                <div className="subscription-item">
                  <h3>Used Credits</h3>
                  <p>
                    {data.monthlyCredits
                      ? Number(
                          data.monthlyCredits.running_credits.toFixed(0)
                        ).toLocaleString()
                      : 0}
                  </p>
                </div>
                <div className="subscription-item">
                  <h3>Remaining Credits</h3>
                  <p>
                    {data.monthlyCredits
                      ? Number(
                          data.monthlyCredits.total_credits.toFixed(0)
                        ).toLocaleString()
                      : 0}
                  </p>
                </div>
                <div className="subscription-item">
                  <h3>Duration Period:</h3>
                  <p>
                    From:{" "}
                    {data.monthlyCredits
                      ? formatDate(
                          new Date(
                            data.monthlyCredits.start_date
                          ).toLocaleString()
                        )
                      : "None"}
                  </p>
                  <p>
                    To:{" "}
                    {data.monthlyCredits?.expiry_date
                      ? formatDate(
                          new Date(
                            data.monthlyCredits.expiry_date
                          ).toLocaleString()
                        )
                      : "None"}
                  </p>
                </div>
                <div className="subscription-item">
                  <h3>Price</h3>
                  <p>
                    $
                    {data.monthlyCredits
                      ? data.monthlyCredits.price.toFixed(2)
                      : 0}
                  </p>
                </div>
              </div>
            </div>
          )}
        </Tabs.Content>
        <Tabs.Content value="topup" className="tab-content">
          <Tabs.Root
            defaultValue="lifetime"
            value={topUpValue}
            onValueChange={setTopUpValue}
          >
            {/* Overview Panel */}
            <Tabs.Content value="lifetime" className="tab-content">
              <h1 className="credit-balance2">No expiry credits</h1>
              {loading && (
                <div className="subscription-panel">
                  <div className="gridMysubs">
                    <div className="subscription-item">
                      <h3>Used Credits</h3>
                      <p>Loading...</p>
                    </div>
                    <div className="subscription-item">
                      <h3>Remaining Credits</h3>
                      <p>Loading...</p>
                    </div>
                    <div className="subscription-item">
                      <h3>Duration Period: </h3>
                      <p>Loading...</p>
                    </div>
                    <div className="subscription-item">
                      <h3>Price</h3>
                      <p>Loading...</p>
                    </div>
                  </div>
                </div>
              )}
              {data && (
                <div className="subscription-panel">
                  <div className="gridMysubs">
                    <div className="subscription-item">
                      <h3>Used Credits</h3>
                      <p>
                        {data?.boughtLifetime
                          ? Number(
                              data.boughtLifetime.running_credits.toFixed(0)
                            ).toLocaleString()
                          : 0}
                      </p>
                    </div>
                    <div className="subscription-item">
                      <h3>Remaining Credits</h3>
                      <p>
                        {data?.boughtLifetime
                          ? Number(
                              data.boughtLifetime.total_credits.toFixed(0)
                            ).toLocaleString()
                          : 0}
                      </p>
                    </div>
                    <div className="subscription-item">
                      <h3>Duration Period:</h3>
                      <p>
                        From:{" "}
                        {data?.boughtLifetime
                          ? formatDate(
                              new Date(
                                data.boughtLifetime.start_date
                              ).toLocaleString()
                            )
                          : "None"}
                      </p>
                      <p>
                        To:{" "}
                        {data?.boughtLifetime?.expiry_date
                          ? formatDate(
                              new Date(
                                data.boughtLifetime.expiry_date
                              ).toLocaleString()
                            )
                          : "None"}
                      </p>
                    </div>
                    <div className="subscription-item">
                      <h3>Price</h3>
                      <p>
                        $
                        {data?.boughtLifetime
                          ? data.boughtLifetime.price.toFixed(2)
                          : 0}
                      </p>
                    </div>
                  </div>
                </div>
              )}
            </Tabs.Content>

            {/* <Tabs.Content value="monthly" className="tab-content">
              <h1 className="credit-balance2">With expiry credits</h1>
              {loading && (
                <div className="subscription-panel">
                  <div className="gridMysubs">
                    <div className="subscription-item">
                      <h3>Used Credits</h3>
                      <p>Loading...</p>
                    </div>
                    <div className="subscription-item">
                      <h3>Remaining Credits</h3>
                      <p>Loading...</p>
                    </div>
                    <div className="subscription-item">
                      <h3>Duration Period: </h3>
                      <p>Loading...</p>
                    </div>
                    <div className="subscription-item">
                      <h3>Price</h3>
                      <p>Loading...</p>
                    </div>
                  </div>
                </div>
              )}
              {data && (
                <div className="subscription-panel">
                  <div className="gridMysubs">
                    <div className="subscription-item">
                      <h3>Used Credits</h3>
                      <p>
                        {data.boughtMonthly
                          ? data.boughtMonthly.running_credits.toFixed(0)
                          : 0}
                      </p>
                    </div>
                    <div className="subscription-item">
                      <h3>Remaining Credits</h3>
                      <p>
                        {data.boughtMonthly
                          ? data.boughtMonthly.total_credits.toFixed(0)
                          : 0}
                      </p>
                    </div>
                    <div className="subscription-item">
                      <h3>Duration Period:</h3>
                      <p>
                        From:{" "}
                        {data.boughtMonthly
                          ? formatDate(
                              new Date(
                                data.boughtMonthly.start_date
                              ).toLocaleString()
                            )
                          : "None"}
                      </p>
                      <p>
                        To:{" "}
                        {data.boughtMonthly.expiry_date
                          ? formatDate(
                              new Date(
                                data.boughtMonthly.expiry_date
                              ).toLocaleString()
                            )
                          : "None"}
                      </p>
                    </div>
                    <div className="subscription-item">
                      <h3>Price</h3>
                      <p>
                        $
                        {data.boughtMonthly
                          ? data.boughtMonthly.price.toFixed(2)
                          : 0}
                      </p>
                    </div>
                  </div>
                </div>
              )}
            </Tabs.Content> */}
            <Tabs.List className="tabs-list" style={{ gap: "5px" }}>
              <Tabs.Trigger
                value="lifetime"
                className={topUpValue === "lifetime" ? "tab-top-up-active" : ""}
                style={{ borderRadius: "10px", border: "solid 1px #828282" }}
              >
                Lifetime
              </Tabs.Trigger>
              {/* <Tabs.Trigger
                value="monthly"
                className={topUpValue === "monthly" ? "tab-top-up-active" : ""}
                style={{ borderRadius: "10px", border: "solid 1px #828282" }}
              >
                Monthly
              </Tabs.Trigger> */}
            </Tabs.List>
          </Tabs.Root>
        </Tabs.Content>
      </Tabs.Root>

      <div className="auto-recharge-section">
        {loadingCallFunc ? (
          <p>Loading</p>
        ) : (
          <>
            <p>Auto recharge is {autoData.active ? autoData.active : "OFF"}</p>
            {autoData.active === "ON" ? (
              <p>
                When your credit balance reaches ${autoData.threshold_amount},
                your payment method will be charged to bring the balance up to $
                {autoData.recharge_amount}.
              </p>
            ) : (
              ""
            )}
          </>
        )}

        <Dialog.Root open={openAutoRecharge} onOpenChange={setOpenAutoRecharge}>
          <Dialog.Trigger asChild>
            <button className="modify-btn">Modify</button>
          </Dialog.Trigger>

          <Dialog.Portal>
            <div className="sideBar-subs-panel">
              <div className="overlay"></div>
              <Dialog.Overlay className="DialogOverlay" />
              <Dialog.Content className="DialogContent DialogCustomWidth-signup">
                <Dialog.Title className="DialogTitle"></Dialog.Title>
                <Dialog.Description className="DialogDescription"></Dialog.Description>
                {/* <AutoRechargeSettings
                    setPaymentMethodId={handleSavePaymentMethod} // Callback to save the payment method ID
                    existingPaymentMethodId={paymentMethodId} // Pass down existing payment method
                    autoData={autoData}
                  /> */}

                <h3>Auto recharge settings</h3>
                <div className="form-group flex">
                  <Switch
                    checked={autoRecharge}
                    onChange={() => setAutoRecharge(!autoRecharge)}
                    inputProps={{ "aria-label": "controlled" }}
                    className="color-of-toggle-auto-recharge"
                  />
                  <label className="flex">
                    Yes, automatically recharge my card when my credit balance
                    falls below a threshold
                  </label>
                </div>
                <div className="auto-topup-error">
                  {errorMessage || errorMessage1 ? (
                    <p className="error-message error-top-up">{errorMessage}</p>
                  ) : (
                    ""
                  )}
                </div>

                <div className="form-group">
                  <label htmlFor="threshold">
                    When credit balance goes below
                  </label>

                  <div className="top-up-modal">
                    <span
                      className={`currency-symbol ${
                        isFocused ? "currency-symbol-focus" : ""
                      }`}
                    >
                      $
                    </span>
                    <input
                      type="number"
                      id="threshold"
                      value={thresholdAmount}
                      className={`topUp-input ${
                        errorMessage ? "input-error" : ""
                      } `}
                      onChange={handleThresholdChange}
                      onFocus={() => setIsFocused(true)}
                      onBlur={() => setIsFocused(false)}
                      readOnly={!autoRecharge}
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="recharge-amount">
                      Bring credit balance back up to
                    </label>

                    <div className="top-up-modal">
                      <span
                        className={`currency-symbol ${
                          isFocused1 ? "currency-symbol-focus" : ""
                        }`}
                      >
                        $
                      </span>

                      <input
                        type="number"
                        id="recharge-amount"
                        value={rechargeAmount}
                        onChange={handleRechargeAmountChange}
                        className={`topUp-input ${
                          errorMessage1 ? "input-error" : ""
                        } `}
                        onFocus={() => setIsFocused1(true)}
                        onBlur={() => setIsFocused1(false)}
                        readOnly={!autoRecharge}
                      />
                    </div>
                  </div>

                  <div className={`flex flex-col card-set-recharge-space `}>
                    <div
                      className={`card-element-auto-recharge ${
                        paymentMethod ? "trans-back" : ""
                      }`}
                    >
                      {!paymentMethod ? (
                        <CardElement onChange={handleCardChange} />
                      ) : (
                        <div className="existing-card">
                          <h4>Existing Card:</h4>
                          <p>**** **** **** {paymentMethod.card.last4}</p>
                          <p>
                            Expiry: {paymentMethod.card.exp_month}/
                            {paymentMethod.card.exp_year}
                          </p>
                          <p>{paymentMethod.card.brand}</p>
                        </div>
                      )}
                    </div>
                    <div className="flex justify-end">
                      {paymentMethod ? (
                        ""
                      ) : (
                        <button
                          onClick={handleSavePaymentMethod}
                          className={`save-button button-auto-recharge-save ${
                            !isCardValid ? "invalid-card" : ""
                          }`}
                          disabled={!isCardValid || loadingCard}
                        >
                          {loadingCard
                            ? "Saving"
                            : "Save Card for Automatic Top-Up"}
                        </button>
                      )}
                    </div>
                  </div>
                </div>
                <div className="button-group">
                  <button
                    onClick={handleSave}
                    className={`save-button ${
                      !paymentMethod ? "invalid-card" : ""
                    }`}
                    disabled={!paymentMethod || loadingSaveAutoRecharge}
                  >
                    {loadingSaveAutoRecharge ? "Saving" : "Save settings"}
                  </button>

                  <Dialog.Close asChild>
                    <button className="cancel-plan-btn">Cancel</button>
                  </Dialog.Close>
                </div>

                <div
                  style={{
                    display: "flex",
                    marginTop: 25,
                    justifyContent: "flex-end",
                  }}
                ></div>
                <Dialog.Close asChild>
                  <button className="IconButton" aria-label="Close">
                    <img
                      src={crossicon}
                      alt="icon"
                      style={{
                        width: "20px",
                        height: "20px",

                        filter: "invert(1)",
                      }}
                    />
                  </button>
                </Dialog.Close>
              </Dialog.Content>
            </div>
          </Dialog.Portal>
        </Dialog.Root>
      </div>

      <div className="action-buttons">
        <Dialog.Root open={openCustomTopUp} onOpenChange={setOpenCustomTopUp}>
          <Dialog.Trigger asChild>
            <button className="add-credit-btn">
              Add to credit balance (custom)
            </button>
          </Dialog.Trigger>

          <Dialog.Portal>
            <div className="sideBar-subs-panel">
              <div className="overlay"></div>
              <Dialog.Overlay className="DialogOverlay" />
              <Dialog.Content className="DialogContent DialogCustomWidth-signup">
                <Dialog.Title className="DialogTitle"></Dialog.Title>
                <Dialog.Description className="DialogDescription"></Dialog.Description>

                <CustomTopUp />
                <div
                  style={{
                    display: "flex",
                    marginTop: 25,
                    justifyContent: "flex-end",
                  }}
                >
                  <Dialog.Close asChild></Dialog.Close>
                </div>
                <Dialog.Close asChild>
                  <button className="IconButton" aria-label="Close">
                    <img
                      src={crossicon}
                      alt="icon"
                      style={{
                        width: "20px",
                        height: "20px",

                        filter: "invert(1)",
                      }}
                    />
                  </button>
                </Dialog.Close>
              </Dialog.Content>
            </div>
          </Dialog.Portal>
        </Dialog.Root>
        <Dialog.Root open={openModalPromo} onOpenChange={setOpenModalPromo}>
          <Dialog.Trigger asChild>
            <button className="add-credit-btn">Buy Credits</button>
          </Dialog.Trigger>

          <Dialog.Portal>
            <div className="sideBar-subs-panel">
              <div className="overlay"></div>
              <Dialog.Overlay className="DialogOverlay" />
              <Dialog.Content className="DialogContent DialogCustomWidth">
                <Dialog.Title className="DialogTitle"></Dialog.Title>
                <Dialog.Description className="DialogDescription"></Dialog.Description>

                <Tabs.Root
                  defaultValue="monthlySubscription"
                  value={buyCreditsValue}
                  onValueChange={setBuyCreditsValue}
                >
                  <div className="subscription-options">
                    <div className="option">
                      <h2>Monthly subscriptions</h2>
                      <ul>
                        <li>Cheaper credits </li>
                        <li>Credits refresh every month</li>
                        <li>
                          Includes full access to OpenAI and Anthropic models
                        </li>
                      </ul>
                    </div>
                    <div className="option">
                      <h2>Pay as you go</h2>
                      <ul>
                        <li>Lifetime credits cost more but never expire</li>
                        <li>Monthly credits cost less but will expire</li>
                        <li>
                          Includes full access to OpenAI and Anthropic models
                        </li>
                      </ul>
                    </div>
                  </div>

                  <Tabs.List className="tabs-list">
                    <Tabs.Trigger
                      value="monthlySubscription"
                      className={
                        buyCreditsValue === "monthlySubscription"
                          ? "tab-active"
                          : ""
                      }
                    >
                      Monthly
                    </Tabs.Trigger>
                    <Tabs.Trigger
                      value="payAsYouGo"
                      className={
                        buyCreditsValue === "payAsYouGo" ? "tab-active" : ""
                      }
                    >
                      Pay as you go
                    </Tabs.Trigger>
                  </Tabs.List>
                  <Tabs.Content
                    value="monthlySubscription"
                    className="tab-content"
                    tabIndex={-1}
                  >
                    <SubscriptionPanel userSubstype={userSubstype} />
                  </Tabs.Content>
                  <Tabs.Content
                    value="payAsYouGo"
                    className="tab-content"
                    tabIndex={-1}
                  >
                    <BuyCreditsPanel isNewUser={isNewUser} />
                  </Tabs.Content>
                </Tabs.Root>

                <div
                  style={{
                    display: "flex",
                    marginTop: 25,
                    justifyContent: "flex-end",
                  }}
                >
                  <Dialog.Close asChild></Dialog.Close>
                </div>
                <Dialog.Close asChild>
                  <button className="IconButton" aria-label="Close">
                    <img
                      src={crossicon}
                      alt="icon"
                      style={{
                        width: "20px",
                        height: "20px",

                        filter: "invert(1)",
                      }}
                    />
                  </button>
                </Dialog.Close>
              </Dialog.Content>
            </div>
          </Dialog.Portal>
        </Dialog.Root>

        <Dialog.Root
          open={isOpen}
          onOpenChange={(open) => {
            setIsOpen(open);
          }}
        >
          <Dialog.Trigger asChild>
            {!isSubscribed ? (
              ""
            ) : (
              <button
                className="cancel-plan-btn"
                onClick={() => setIsOpen(true)}
              >
                Cancel plan
              </button>
            )}
          </Dialog.Trigger>

          <Dialog.Portal>
            <div className="sideBar-subs-panel">
              <div className="overlay"></div>
              <Dialog.Overlay className="DialogOverlay" />
              <Dialog.Content className="DialogContent DialogCustom">
                <Dialog.Title className="DialogTitle"></Dialog.Title>
                <Dialog.Description className="DialogDescription"></Dialog.Description>
                <div className="subscription-panel">
                  <h2>Cancel plan</h2>
                  <p className="description">
                    You'll be able to use the remaining credits in your account,
                    after which we will begin rejecting your API requests and no
                    further charges will be made.
                  </p>
                </div>
                <div className="flex content-end gap-10">
                  <Dialog.Close asChild>
                    <button className="no-button" aria-label="Close">
                      Nevermind
                    </button>
                  </Dialog.Close>
                  <button
                    className="yes-button cancel-plan-button"
                    aria-label="Close"
                    onClick={cancelSubsMonthly}
                  >
                    {loadingCancelSubs ? "Canceling" : "Cancel plan"}
                  </button>
                </div>

                <div
                  style={{
                    display: "flex",
                    marginTop: 25,
                    justifyContent: "flex-end",
                  }}
                ></div>
                <Dialog.Close asChild>
                  <button className="IconButton" aria-label="Close">
                    <img
                      src={crossicon}
                      alt="icon"
                      style={{
                        width: "20px",
                        height: "20px",

                        filter: "invert(1)",
                      }}
                    />
                  </button>
                </Dialog.Close>
              </Dialog.Content>
            </div>
          </Dialog.Portal>
        </Dialog.Root>

        <Dialog.Root
          open={isOpen1}
          onOpenChange={(open) => {
            setIsOpen1(open);
            fetchSubStatus();
          }}
        >
          <Dialog.Trigger asChild></Dialog.Trigger>

          <Dialog.Portal>
            <div className="sideBar-subs-panel">
              <div className="overlay"></div>
              <Dialog.Overlay className="DialogOverlay" />
              <Dialog.Content className="DialogContent DialogCustomWidth-signup">
                <Dialog.Title className="DialogTitle"></Dialog.Title>
                <Dialog.Description className="DialogDescription"></Dialog.Description>
                <div className="subscription-panel">
                  <h2>Plan Succesfully cancel</h2>
                  <span>
                    Credits in your account are still usable until expiration
                    date.
                  </span>
                </div>
                <div
                  style={{
                    display: "flex",
                    marginTop: 25,
                    justifyContent: "flex-end",
                  }}
                >
                  <Dialog.Close asChild></Dialog.Close>
                </div>
                <Dialog.Close asChild>
                  <button className="IconButton" aria-label="Close">
                    <img
                      src={crossicon}
                      alt="icon"
                      style={{
                        width: "20px",
                        height: "20px",

                        filter: "invert(1)",
                      }}
                    />
                  </button>
                </Dialog.Close>
              </Dialog.Content>
            </div>
          </Dialog.Portal>
        </Dialog.Root>
      </div>
    </div>
  );
};

export default MyBilling;
