import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  BrowserRouter as Router,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import {
  Divider,
  CssBaseline,
  IconButton,
  Drawer,
  AppBar,
  Toolbar,
  List,
  ListItem,
  ListItemText,
  Typography,
  Container,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import SettingsIcon from "@mui/icons-material/Settings";
import Settings from "./Settings";
import { breakpoints, height, styled, useTheme } from "@mui/system";
import NavigationBar from "../screens/Navigationbar";
import * as Dialog from "@radix-ui/react-dialog";
// import "src/chatwindow.css";
import {
  InputTextProps,
  ChatHistory,
  Message,
} from "../components/SendMessage";
import ChatComponent from "../components/ChatComponent";
import Sidebar from "./SideBar";
import SettingsPanel from "./SettingsPanel";
import axios from "axios";
import { AuthProvider, useAuth } from "./AuthContext";
import { useAppContext } from "./LLMContext";
import crossicon from "src/assets/cross-2.svg";

interface ContainerProps {
  leftOpen: boolean;
  rightOpen: boolean;
}
const drawerWidth = 306;
const Root = styled("div")({
  display: "flex",
  flexDirection: "column",
  minHeight: "100vh",
  backgroundColor: "#121212",
});

const TopAppBar = styled(AppBar)({
  zIndex: 1201,
  backgroundColor: "#1f1f1f",
});

const SecondaryAppBar = styled(AppBar)({
  top: "64px",
  zIndex: 1200,
  backgroundColor: "#1f1f1f",
});

const ContentContainer = styled("div")<{
  leftOpen: boolean;
  rightOpen: boolean;
}>(({ leftOpen, rightOpen }) => ({
  display: "flex",
  flexGrow: 1,
  margin: "70px 20px 0 20px", // Adjust based on the height of both app bars
  transition:
    "margin-left 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms, margin-right 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms",

  marginLeft: leftOpen ? `${drawerWidth}px` : "0px",
  marginRight: rightOpen ? `${drawerWidth}px` : "0px",
  backgroundColor: "#1f1f1f",
}));

const MainContent = styled("main")<{
  leftOpen: boolean;
  rightOpen: boolean;
}>(({ leftOpen, rightOpen }) => ({
  flexGrow: 1,
  padding: "16px 16px 0",
  transition:
    "margin-left 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms, margin-right 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms",

  backgroundColor: "#121212",
}));

const SideDrawer = styled(Drawer)<{
  variant: "persistent";
  anchor: "left" | "right";
  open: boolean;
}>(({ theme, anchor }) => ({
  "& .MuiDrawer-paper": {
    width: drawerWidth,
    marginTop: "0", // Start just below the two app bars
    [theme.breakpoints.up("sm")]: {
      top: "0",
    },
    backgroundColor: "#222",
  },
  zIndex: 1100,
}));
/* separated style component */
/*menu*/
const IconContainer1 = styled("div")<{
  leftOpen: boolean;
}>(({ leftOpen }) => ({
  position: "absolute",
  left: "0",
  top: "74px",
  bottom: "0",
  padding: "8px",
  // zIndex: 1201,
  color: "#F6F7F9",
  height: "90vh",
}));

/*settings*/
const IconContainer2 = styled("div")<{
  rightOpen: boolean;
}>(({ rightOpen }) => ({
  position: "absolute",
  right: "0",
  top: "74px",
  padding: "8px",
  // zIndex: 1201,
  color: "#F6F7F9",
}));

export function CloseButton({ handler, rightORleft }) {
  return (
    <a type="button" className={rightORleft} onClick={handler}>
      X
    </a>
  );
}

const ChatScreenWindow: React.FC<InputTextProps> = ({ disabled }) => {
  const {
    selectedModel,
    setSelectedModel,
    selectedCreator,
    setSelectedCreator,
    selectedLLM,
    setSelectedLLM,
  } = useAppContext();

  const { session, setSession } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (session !== "loading" && !session?.user) {
      navigate("/login");
    }
  }, [session]);
  const user_id = session?.user?.user_metadata.user_id;
  const [searchParams, setSearchParams] = useSearchParams();
  let type = searchParams.get("type");
  const [isOpen, setIsOpen] = useState(false);
  const openDialog = () => setIsOpen(true);
  const closeDialog = () => setIsOpen(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const openDialog2 = () => setIsOpen2(true);
  const closeDialog2 = () => setIsOpen2(false);
  const [isOpen3, setIsOpen3] = useState(false);
  const openDialog3 = () => setIsOpen3(true);
  const closeDialog3 = () => setIsOpen3(false);

  useEffect(() => {
    if (type !== null) {
      switch (type) {
        case "custom":
          openDialog();
          const newSearchParams1 = new URLSearchParams(searchParams);
          newSearchParams1.delete("type");
          setSearchParams(newSearchParams1);
          type = "";

          break;
        case "payment":
          openDialog2();
          const newSearchParams2 = new URLSearchParams(searchParams);
          newSearchParams2.delete("type");
          setSearchParams(newSearchParams2);
          type = "";
          break;
        case "subscription":
          openDialog3();
          const newSearchParams3 = new URLSearchParams(searchParams);
          newSearchParams3.delete("type");
          setSearchParams(newSearchParams3);
          type = "";
          break;
        default:
          break;
      }
    }
  }, []);

  // if (!session) {
  //   navigate("/");
  // }

  const [userSubstype, setUserSubstype] = useState<number>(0);
  const [isNewUser, setIsNewUser] = useState<boolean>(false);
  useEffect(() => {
    const urlEnv = process.env.REACT_APP_SERVER_ACCESS;

    const checkIfNewUserAndSubscriptionType = async () => {
      try {
        const response = await axios.get(
          `${urlEnv}checkIfNewUserAndSubscriptionType`,
          {
            params: { user_id: user_id },
          }
        );
        // console.log("checkifnewuser", response.data.subscription);

        if (response.data.subscription) {
          setUserSubstype(response.data.subscription);
          // console.log("set user subs type");
        } else {
          //setUserSubstype("");
        }

        if (response.data.success !== false) {
          setIsNewUser(true);

          // console.log("set user role type");
        } else {
          setIsNewUser(false);
          // console.log("yayaya");
        }
      } catch (err) {
        console.error("error fething checkIfNewUser:", err.message);
      }
    };
    checkIfNewUserAndSubscriptionType();
  }, [user_id]);

  const theme = useTheme();
  const [leftOpen, setLeftOpen] = React.useState(true);
  const [rightOpen, setRightOpen] = React.useState(false);
  // const [chats, setChats] = useState<ChatHistory[]>([]);

  //this will save the chats to local/ using cache
  const [chats, setChats] = useState<ChatHistory[]>(() => {
    const savedChats = localStorage.getItem("chats");
    return savedChats ? JSON.parse(savedChats) : [];
  });
  useEffect(() => {
    localStorage.setItem("chats", JSON.stringify(chats));
  }, [chats]);

  const [selectedChatId, setSelectedChatId] = useState<number | null>(null);
  const initialLoad = useRef(true);
  const location = useLocation();
  const fromWelcomeScreen = location.state?.fromWelcomeScreen;

  const [systemInstructions, setSystemInstructions] = useState<string>(() => {
    const savedSystemInstructions =
      sessionStorage.getItem("systemInstructions");
    return savedSystemInstructions || "";
  });
  const [temperature, setTemperature] = useState<number>(() => {
    const savedTemperature = sessionStorage.getItem("temperature");
    return savedTemperature ? parseFloat(savedTemperature) : 1;
  });
  const [maxTokens, setMaxTokens] = useState<number>(() => {
    const savedMaxTokens = sessionStorage.getItem("maxTokens");
    return savedMaxTokens !== null ? parseInt(savedMaxTokens) : 100;
  });

  const [topP, setTopP] = useState<number>(() => {
    const savedTopP = sessionStorage.getItem("topP");
    return savedTopP !== null ? parseFloat(savedTopP) : 1;
  });

  const [stopSequences, setStopSequences] = useState<string[]>(() => {
    const savedStopSequences = sessionStorage.getItem("stopSequences");

    return savedStopSequences ? JSON.parse(savedStopSequences) : [];
  });

  const [frequencyPenalty, setFrequencyPenalty] = useState<number>(() => {
    const savedFrequencyPenalty = sessionStorage.getItem("frequencyPenalty");
    return savedFrequencyPenalty !== null
      ? parseFloat(savedFrequencyPenalty)
      : 0;
  });

  const [presencePenalty, setPresencePenalty] = useState<number>(() => {
    const savedPresencePenalty = sessionStorage.getItem("presencePenalty");
    return savedPresencePenalty !== null ? parseFloat(savedPresencePenalty) : 0;
  });

  useEffect(() => {
    sessionStorage.setItem("systemInstructions", systemInstructions);
  }, [systemInstructions]);

  useEffect(() => {
    sessionStorage.setItem("temperature", temperature.toString());
  }, [temperature]);

  useEffect(() => {
    sessionStorage.setItem("maxTokens", maxTokens.toString());
  }, [maxTokens]);

  useEffect(() => {
    sessionStorage.setItem("topP", topP.toString());
  }, [topP]);

  useEffect(() => {
    if (stopSequences.length > 0) {
      sessionStorage.setItem("stopSequences", JSON.stringify(stopSequences));
    }
  }, [stopSequences]);

  useEffect(() => {
    sessionStorage.setItem("frequencyPenalty", frequencyPenalty.toString());
  }, [frequencyPenalty]);

  useEffect(() => {
    sessionStorage.setItem("presencePenalty", presencePenalty.toString());
  }, [presencePenalty]);

  useEffect(() => {
    const chatExists = chats.length > 0;
    const chatInitialized =
      sessionStorage.getItem("chatInitialized") === "true";
    if (
      !fromWelcomeScreen &&
      initialLoad.current &&
      !chatExists &&
      !chatInitialized
    ) {
      initialLoad.current = false;
      // Find the maximum existing ID
      const maxId = chats.reduce((max, chat) => Math.max(max, chat.id), -0);
      const newChat: ChatHistory = {
        // thread_id: 263,
        id: maxId + 1,
        title: `Chat ${maxId + 1}`,
        messages: [],
        creator: selectedCreator,
        model: selectedModel,
        systemInstructions: systemInstructions,
        temperature: temperature,
        maxTokens: maxTokens,
        stopSequences: stopSequences,
        //set to optional for Anthropic creator
        topP: topP,
        frequencyPenalty: frequencyPenalty,
        presencePenalty: presencePenalty,
      };
      setChats((prevChats) => [...prevChats, newChat]);
      setSelectedChatId(newChat.id);
      sessionStorage.setItem("chatInitialized", "true");
      sessionStorage.removeItem("selectedCreator");
      sessionStorage.removeItem("selectedModel");
      // console.log("newChat", newChat);
    }
  }, []);

  const handleLeftDrawerToggle = () => {
    setLeftOpen(!leftOpen);
  };

  const handleRightDrawerToggle = () => {
    setRightOpen(!rightOpen);
  };

  const updateChatMessages = (
    chatId: number,
    messages: Message[],
    creator: string,
    model: string,
    systemInstructions: string,
    temperature: number,
    maxTokens: number,
    stopSequences: string[],
    topP?: number,
    frequencyPenalty?: number,
    presencePenalty?: number
  ) => {
    const updatedChats = chats.map((chat) => {
      // console.log("yachatId", chatId);
      // console.log("yamessages", messages);
      // console.log("yacreator", creator);
      // console.log("yamodel", model);
      // console.log("yasystemInstructions", systemInstructions);
      // console.log("yatemperature", temperature);
      // console.log("yamaxTokens", maxTokens);
      // console.log("yastopSequences", stopSequences);
      // console.log("yatopP", topP);
      // console.log("yafrequencyPenalty", frequencyPenalty);
      // console.log("yapresencePenalty", presencePenalty);

      if (chat.id === chatId) {
        return {
          ...chat,
          messages: messages === null ? [...chat.messages] : messages,
          creator,
          model,
          systemInstructions,
          temperature,
          maxTokens,
          stopSequences,
          topP,
          frequencyPenalty,
          presencePenalty,
        };
      }
      return chat;
    });
    setChats(updatedChats);
  };

  const handleNewChat = useCallback(() => {
    // Find the maximum existing ID
    const maxId = chats.reduce((max, chat) => Math.max(max, chat.id), -0);

    const newChat: ChatHistory = {
      // thread_id: 263,
      id: maxId + 1,
      title: `Chat ${maxId + 1}`,
      messages: [],
      creator: selectedCreator,
      model: selectedModel,
      systemInstructions: systemInstructions,
      temperature: temperature,
      maxTokens: maxTokens,
      stopSequences: stopSequences,
      //set to optional for Anthropic creator
      topP: topP,
      frequencyPenalty: frequencyPenalty,
      presencePenalty: presencePenalty,
    };
    // setSelectedModel(newChat.model);
    // setSelectedCreator(newChat.creator);
    setChats((prevChats) => [...prevChats, newChat]);
    setSelectedChatId(newChat.id);
    // console.log("newChat", newChat);
  }, [chats.length]);

  const [isDeletingChats, setIsDeletingChats] = useState(false);
  const hasCreatedInitialChat = useRef(false);

  const removeAllItems = () => {
    sessionStorage.removeItem("systemInstructions");
    sessionStorage.removeItem("temperature");
    sessionStorage.removeItem("maxTokens");
    sessionStorage.removeItem("topP");
    sessionStorage.removeItem("stopSequences");
    sessionStorage.removeItem("frequencyPenalty");
    sessionStorage.removeItem("presencePenalty");
  };

  const deleteChat = async (chatId: number) => {
    removeAllItems();
    // console.log("chatId", chatId);
    setIsDeletingChats(true);

    //const threadChatId = await fetchChatThreadID(chatId, user_id);
    // console.log("chatThreadIDchatThreadID", threadChatId);

    //if (threadChatId) {
    const urlEnv = process.env.REACT_APP_SERVER_ACCESS;
    // console.log("urlEnv", urlEnv);
    try {
      const response = await axios.post(`${urlEnv}delete-chat-db`, {
        chatId: chatId,
        //chatThreadID: threadChatId,
        user_id: user_id,
      });
      if (response) {
        // console.log(response.data);
      } else {
        // console.log("chat from DB deleted");
      }
      console.log("deleting chat");
    } catch (error) {
      console.error("deleting chat error", error);
    }
    // }

    const updatedChats = chats.filter((chat) => chat.id !== chatId);
    setChats(updatedChats);
    // console.log("chatIdchatIdchatId", chatId);
    if (selectedChatId === chatId) {
      setSelectedChatId(
        updatedChats.length > 0
          ? updatedChats[updatedChats.length - 1].id
          : null
      );
    }
    setIsDeletingChats(false);
    // return false;
  };

  const prevChatsLength = useRef(chats.length);
  useEffect(() => {
    if (chats.length < prevChatsLength.current) {
      chats.filter((chat) => {
        if (chat.id === selectedChatId) {
          setSelectedModel(chat.model);
          setSelectedCreator(chat.creator);
          setSystemInstructions(chat.systemInstructions);
          setTemperature(chat.temperature);
          setMaxTokens(chat.maxTokens);
          setStopSequences(chat.stopSequences);
          setTopP(chat.topP);
          setFrequencyPenalty(chat.frequencyPenalty);
          setPresencePenalty(chat.presencePenalty);
        }
      });
    }

    prevChatsLength.current = chats.length; // Update the ref with the current length after the effect runs
  }, [chats.length]);

  const fetchChatThreadID = async (chat_id, user_id) => {
    const urlEnv = process.env.REACT_APP_SERVER_ACCESS + "getThreadId";

    const response = await axios.get(urlEnv, {
      params: {
        chat_id: chat_id,
        user_id: user_id,
      },
    });

    // console.log(response.data.thread_id);

    if (response.data) {
      return response.data.thread_id;
    } else if (!response.data) {
      return undefined;
    }
  };

  const handleSelectChat = (chatId: number) => {
    setSelectedChatId(chatId);
    // console.log("chat.id", chatId);
    chats.filter((chat) => {
      if (chat.id === chatId) {
        setSelectedModel(chat.model);
        setSelectedCreator(chat.creator);
        setSystemInstructions(chat.systemInstructions);
        setTemperature(chat.temperature);
        setMaxTokens(chat.maxTokens);
        setStopSequences(chat.stopSequences);
        setTopP(chat.topP);
        setFrequencyPenalty(chat.frequencyPenalty);
        setPresencePenalty(chat.presencePenalty);
      }
    });
  };

  const [hasSentInitialMessage, setHasSentInitialMessage] = useState(() => {
    return sessionStorage.getItem("hasSentInitialMessage") === "true";
  });
  const [isChatLoaded, setIsChatLoaded] = useState(false);
  const sendMessageButtonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (
      !hasSentInitialMessage &&
      fromWelcomeScreen &&
      isChatLoaded &&
      sendMessageButtonRef.current
    ) {
      sendMessageButtonRef.current.click();

      sessionStorage.setItem("hasSentInitialMessage", "true");
    }
  }, [fromWelcomeScreen, isChatLoaded]);

  const [isDropdownDisabled, setIsDropdownDisabled] = useState(false);
  // const [isSystemInstructionDisabled, setIsSystemInstructionDisabled] =
  //   useState(false);
  // const [isTemperatureDisabled, setIsTemperatureDisabled] = useState(false);
  // const [isMaxTokensDisabled, setIsMaxTokensDisabled] = useState(false);
  // const [isStopSequencesDisabled, setIsStopSequencesDisabled] = useState(false);
  // const [isTopPDisabled, setIsTopPDisabled] = useState(false);
  // const [isFrequencyPenaltyDisabled, setIsFrequencyPenaltyDisabled] =
  //   useState(false);
  // const [isPresencePenaltyDisabled, setIsPresencePenaltyDisabled] =
  //   useState(false);

  useEffect(() => {
    const selectedChats = chats.filter((chat) => chat.id === selectedChatId);

    selectedChats.map((chat, index) => {
      if (chat.messages && chat.messages.length > 0) {
        setIsDropdownDisabled(true);
      } else {
        setIsDropdownDisabled(false);
      }
    });
  }, [selectedChatId, chats]);

  useEffect(() => {
    const validateFrontend = async () => {
      // console.log("calling yoyoyo");
      if (session && session.user) {
        const response = await axios.post(
          `${process.env.REACT_APP_SERVER_ACCESS}check-paid-session`,
          { email: session.user.email }
        );

        if (response.data) {
          session.user.user_metadata = {
            ...session.user.user_metadata,
            paid_session: true,
          };
        } else if (!response) {
          session.user.user_metadata = {
            ...session.user.user_metadata,
            paid_session: false,
          };
        }

        // console.log(data.session);
        setSession(session);
        // localStorage.setItem(
        //   "supabaseSession",
        //   JSON.stringify(data.session)
        // );
      }
    };
    validateFrontend();
  }, []);

  const [data, setData] = useState(null);

  const [loading, setLoading] = useState(true);
  const fetchData = async () => {
    setLoading(true);
    const urlEnv = process.env.REACT_APP_SERVER_ACCESS;
    try {
      const response = await axios.post(`${urlEnv}fetch-credits-history`, {
        user_id: user_id,
      });
      console.log("responseasdasdsad", response.data);
      setData(response.data);
    } catch (err) {
      console.log(err.message || "An error occurred");
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, [chats, user_id]);

  return (
    <>
      <Root>
        <CssBaseline />
        <TopAppBar>
          <NavigationBar data={data} loading={loading} />
          {/* Include NavigationBar inside the top app bar */}
        </TopAppBar>
        {/* menu button */}
        <IconContainer1 leftOpen={leftOpen}>
          {!leftOpen ? (
            <IconButton
              color="inherit"
              aria-label="open left drawer"
              onClick={handleLeftDrawerToggle}
            >
              {!leftOpen ? <MenuIcon /> : ""}
              {/*<MenuIcon />*/}
            </IconButton>
          ) : (
            ""
          )}{" "}
        </IconContainer1>
        {/* seting button */}
        {/* <IconContainer2 rightOpen={rightOpen}>
          {!rightOpen ? (
            <IconButton
              color="inherit"
              aria-label="open right drawer"
              onClick={handleRightDrawerToggle}
            >
              {!rightOpen ? <SettingsIcon /> : ""}
              {/*<SettingsIcon />
            </IconButton>
          ) : (
            ""
          )}
        </IconContainer2> */}

        <ContentContainer leftOpen={leftOpen} rightOpen={rightOpen}>
          <SideDrawer variant="persistent" anchor="left" open={leftOpen}>
            <CloseButton
              handler={handleLeftDrawerToggle}
              rightORleft={"close-left"}
            />
            <Sidebar
              chats={chats}
              activeChatId={selectedChatId}
              startNewChat={handleNewChat}
              loadChat={handleSelectChat}
              deleteChat={deleteChat}
              isNewUser={isNewUser}
              userSubstype={userSubstype}
            />
          </SideDrawer>

          {/* message content */}
          <MainContent leftOpen={leftOpen} rightOpen={rightOpen}>
            <Container style={{ height: "100%", maxWidth: "900px" }}>
              <ChatComponent
                selectedChatId={selectedChatId}
                chats={chats}
                updateChatMessages={updateChatMessages}
                selectedModel={selectedModel}
                setSelectedModel={setSelectedModel}
                sendMessageButtonRef={sendMessageButtonRef}
                onChatLoaded={() => setIsChatLoaded(true)}
                onOpenSettings={handleRightDrawerToggle}
                //
                systemInstructions={systemInstructions}
                setSystemInstructions={setSystemInstructions}
                temperature={temperature}
                setTemperature={setTemperature}
                maxTokens={maxTokens}
                setMaxTokens={setMaxTokens}
                topP={topP}
                setTopP={setTopP}
                stopSequences={stopSequences}
                setStopSequences={setStopSequences}
                frequencyPenalty={frequencyPenalty}
                setFrequencyPenalty={setFrequencyPenalty}
                presencePenalty={presencePenalty}
                setPresencePenalty={setPresencePenalty}
                disabled={isDropdownDisabled}
              />
            </Container>
          </MainContent>

          <SideDrawer variant="persistent" anchor="right" open={rightOpen}>
            <CloseButton
              handler={handleRightDrawerToggle}
              rightORleft={"close-right"}
            />
            <SettingsPanel
              // onSettingsChange={handleSettingsChange}
              systemInstructions={systemInstructions}
              setSystemInstructions={setSystemInstructions}
              temperature={temperature}
              setTemperature={setTemperature}
              maxTokens={maxTokens}
              setMaxTokens={setMaxTokens}
              topP={topP}
              setTopP={setTopP}
              stopSequences={stopSequences}
              setStopSequences={setStopSequences}
              frequencyPenalty={frequencyPenalty}
              setFrequencyPenalty={setFrequencyPenalty}
              presencePenalty={presencePenalty}
              setPresencePenalty={setPresencePenalty}
              disabled={isDropdownDisabled}
              startNewChat={handleNewChat}
              fromWelcomeScreen={fromWelcomeScreen}
            />
          </SideDrawer>
        </ContentContainer>
      </Root>

      <Dialog.Root
        open={isOpen}
        onOpenChange={(open) => {
          setIsOpen(open);
        }}
      >
        <Dialog.Trigger asChild></Dialog.Trigger>

        <Dialog.Portal>
          <div className="sideBar-subs-panel">
            <div className="overlay"></div>
            <Dialog.Overlay className="DialogOverlay" />
            <Dialog.Content className="DialogContent DialogCustomWidth-signup">
              <Dialog.Title className="DialogTitle"></Dialog.Title>
              <Dialog.Description className="DialogDescription"></Dialog.Description>
              <div className="subscription-panel">
                <h2>Succesfully topped up</h2>
                <span>Bought Credits already added to your account.</span>
              </div>
              <div
                style={{
                  display: "flex",
                  marginTop: 25,
                  justifyContent: "flex-end",
                }}
              >
                <Dialog.Close asChild></Dialog.Close>
              </div>
              <Dialog.Close asChild>
                <button className="IconButton" aria-label="Close">
                  <img
                    src={crossicon}
                    alt="icon"
                    style={{
                      width: "20px",
                      height: "20px",

                      filter: "invert(1)",
                    }}
                  />
                </button>
              </Dialog.Close>
            </Dialog.Content>
          </div>
        </Dialog.Portal>
      </Dialog.Root>

      <Dialog.Root
        open={isOpen2}
        onOpenChange={(open) => {
          setIsOpen2(open);
        }}
      >
        <Dialog.Trigger asChild></Dialog.Trigger>

        <Dialog.Portal>
          <div className="sideBar-subs-panel">
            <div className="overlay"></div>
            <Dialog.Overlay className="DialogOverlay" />
            <Dialog.Content className="DialogContent DialogCustomWidth-signup">
              <Dialog.Title className="DialogTitle"></Dialog.Title>
              <Dialog.Description className="DialogDescription"></Dialog.Description>
              <div className="subscription-panel">
                <h2>Succesfully bought credits</h2>
                <span>Bought Credits already added to your account.</span>
              </div>
              <div
                style={{
                  display: "flex",
                  marginTop: 25,
                  justifyContent: "flex-end",
                }}
              >
                <Dialog.Close asChild></Dialog.Close>
              </div>
              <Dialog.Close asChild>
                <button className="IconButton" aria-label="Close">
                  <img
                    src={crossicon}
                    alt="icon"
                    style={{
                      width: "20px",
                      height: "20px",

                      filter: "invert(1)",
                    }}
                  />
                </button>
              </Dialog.Close>
            </Dialog.Content>
          </div>
        </Dialog.Portal>
      </Dialog.Root>
      <Dialog.Root
        open={isOpen3}
        onOpenChange={(open) => {
          setIsOpen3(open);
        }}
      >
        <Dialog.Trigger asChild></Dialog.Trigger>

        <Dialog.Portal>
          <div className="sideBar-subs-panel">
            <div className="overlay"></div>
            <Dialog.Overlay className="DialogOverlay" />
            <Dialog.Content className="DialogContent DialogCustomWidth-signup">
              <Dialog.Title className="DialogTitle"></Dialog.Title>
              <Dialog.Description className="DialogDescription"></Dialog.Description>
              <div className="subscription-panel">
                <h2>Succesfully Subscribed to Monthly</h2>
                <span>Credits are already added to your account.</span>
              </div>
              <div
                style={{
                  display: "flex",
                  marginTop: 25,
                  justifyContent: "flex-end",
                }}
              >
                <Dialog.Close asChild></Dialog.Close>
              </div>
              <Dialog.Close asChild>
                <button className="IconButton" aria-label="Close">
                  <img
                    src={crossicon}
                    alt="icon"
                    style={{
                      width: "20px",
                      height: "20px",

                      filter: "invert(1)",
                    }}
                  />
                </button>
              </Dialog.Close>
            </Dialog.Content>
          </div>
        </Dialog.Portal>
      </Dialog.Root>
    </>
  );
};

export default ChatScreenWindow;
export {
  ContentContainer,
  IconContainer1,
  SideDrawer,
  IconContainer2,
  Root,
  TopAppBar,
  MainContent,
};
