import React, { createContext, useState, useEffect, useContext } from "react";
import { supabase } from "./SupabaseClient";
import axios from "axios";
const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [session, setSession] = useState<any | string>("loading");

  useEffect(() => {
    // const storedSession = JSON.parse(localStorage.getItem("supabaseSession"));

    // if (storedSession) {
    //   setSession(storedSession);
    //   if (!localStorage.getItem("hasReloaded")) {
    //     // Set a flag in localStorage to indicate the page has been reloaded
    //     localStorage.setItem("hasReloaded", "true");

    //     // Refresh the page
    //     window.location.reload();
    //   }
    // } else {
    supabase.auth.getSession().then(async ({ data: { session } }) => {
      if (session) {
        // const response = await axios.post(
        //   `${process.env.REACT_APP_SERVER_ACCESS}check-paid-session`,
        //   { email: session.user.email }
        // );
        // console.log("check-paid-session", response.data); //this is the user id
        // if (response.data.subscription) {
        //   // switch (response.data.subscription) {
        //   //   case "monthly":
        //   //     sessionStorage.setItem(
        //   //       "Subscription",
        //   //       response.data.subscription
        //   //     );
        //   //     break;
        //   //   case "yearly":
        //   //     sessionStorage.setItem(
        //   //       "Subscription",
        //   //       response.data.subscription
        //   //     );
        //   //     break;
        //   //   case "new user promo":
        //   //     sessionStorage.setItem(
        //   //       "Subscription",
        //   //       response.data.subscription
        //   //     );
        //   //     break;
        //   //   default:
        //   //     sessionStorage.removeItem("Subscription");
        //   // }
        //   session.user.user_metadata = {
        //     ...session.user.user_metadata,
        //     paid_session: true,
        //   };
        // } else {
        //   session.user.user_metadata = {
        //     ...session.user.user_metadata,
        //     paid_session: false,
        //   };
        // }

        try {
          const urlEnv = `${process.env.REACT_APP_SERVER_ACCESS}get-user-id`;
          const email = session.user.email;
          const response = await axios.get(urlEnv, {
            params: { email: email },
          });

          const user_id = response.data.user_id;

          if (response.data) {
            session.user.user_metadata = {
              ...session.user.user_metadata,
              user_id: user_id,
            };
          } else {
            session.user.user_metadata = {
              ...session.user.user_metadata,
              user_id: null,
            };
          }
          // console.log("User ID:", user_id);
        } catch (error) {
          // Handle errors
          console.error("Error fetching user ID:", error);
        }
        if (!localStorage.getItem("hasReloaded")) {
          // Set a flag in localStorage to indicate the page has been reloaded
          localStorage.setItem("hasReloaded", "true");

          // Refresh the page
          window.location.reload();
        }
        setSession(session);
        // localStorage.setItem("supabaseSession", JSON.stringify(session));
      } else {
        setSession(null);
      }
    });
    // }

    // Listen for changes in the authentication state
    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange(async (_event, session) => {
      if (session) {
        // const response = await axios.post(
        //   `${process.env.REACT_APP_SERVER_ACCESS}check-paid-session`,
        //   { email: session.user.email }
        // );

        // if (response.data) {
        //   session.user.user_metadata = {
        //     ...session.user.user_metadata,
        //     paid_session: true,
        //   };
        // } else {
        //   session.user.user_metadata = {
        //     ...session.user.user_metadata,
        //     paid_session: false,
        //   };
        // }

        try {
          const urlEnv = `${process.env.REACT_APP_SERVER_ACCESS}get-user-id`;

          const email = session.user.email;

          const response = await axios.get(urlEnv, {
            params: { email: email },
          });

          const user_id = response.data.user_id;

          if (response.data) {
            session.user.user_metadata = {
              ...session.user.user_metadata,
              user_id: user_id,
            };
          } else {
            session.user.user_metadata = {
              ...session.user.user_metadata,
              user_id: null,
            };
          }
          // console.log("User ID:", user_id);
        } catch (error) {
          // Handle errors
          console.error("Error fetching user ID:", error);
        }

        if (!localStorage.getItem("hasReloaded")) {
          // Set a flag in localStorage to indicate the page has been reloaded
          localStorage.setItem("hasReloaded", "true");

          // Refresh the page
          window.location.reload();
        }

        setSession(session);
      } else {
        setSession(null);
      }
      if (session) {
        // localStorage.setItem("supabaseSession", JSON.stringify(session));
        setSession(session);
      } else {
        // localStorage.removeItem("supabaseSession");
        setSession(null);
      }
    });

    return () => subscription.unsubscribe();
  }, []);

  return (
    <AuthContext.Provider value={{ session, setSession }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
