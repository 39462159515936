import React, {
  createContext,
  useState,
  ReactNode,
  useContext,
  useEffect,
} from "react";

// Define the context type
interface AppContextType {
  selectedModel: string;
  setSelectedModel: (model: string) => void;
  selectedCreator: string;
  setSelectedCreator: (creator: string) => void;
  selectedLLM: string;
  setSelectedLLM: (llm: string) => void;
}

// Create the context
const AppContext = createContext<AppContextType | undefined>(undefined);

// Provider component that wraps the application and shares the state
export const AppProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [selectedModel, setSelectedModel] = useState<string>("gpt-4o-mini");
  const [selectedCreator, setSelectedCreator] = useState<string>("OpenAI");

  const [selectedLLM, setSelectedLLM] = useState<string>(
    `${selectedCreator}:${selectedModel}`
  );

  const [isDropdownDisabled, setIsDropdownDisabled] = useState(false);

  // Ensure selectedLLM updates when selectedModel or selectedCreator changes
  React.useEffect(() => {
    setSelectedLLM(`${selectedCreator}:${selectedModel}`);
  }, [selectedModel, selectedCreator]);

  return (
    <AppContext.Provider
      value={{
        selectedModel,
        setSelectedModel,
        selectedCreator,
        setSelectedCreator,
        selectedLLM,
        setSelectedLLM,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

// Hook to use the context in any component
export const useAppContext = () => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error("useAppContext must be used within an AppProvider");
  }
  return context;
};
