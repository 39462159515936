import React, { useState, useEffect, useRef } from "react";
import "../styles.css";
import logo from "../assets/logo.png";
import profpic from "../assets/image.jpeg";
import share from "../assets/share.png";
import more from "../assets/more.png";
import axios from "axios";
import { User } from "lucide-react";
import Dropdownllm from "./Llm";
import Anthropic from "@anthropic-ai/sdk";
import InputText from "./SendMessage";
import { Message } from "./SendMessage";
import { ChatHistory } from "./SendMessage";
import { InputTextProps } from "./SendMessage";
import { useLocation, useNavigate } from "react-router-dom";
import { CloseButton, IconContainer2, SideDrawer } from "./ChatScreenWindow";

import JellyOozeLoader from "./Loader";
import TypingEffect from "./TypingEffect";
import { useAppContext } from "./LLMContext";
import logo192 from "../assets/logo192.png";
import SettingsIcon from "@mui/icons-material/Settings";
import ReactMarkdown from "react-markdown";
import { IconButton } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import Button from "@mui/material/Button";

const MarkdownRenderer: React.FC<{ content: string }> = ({ content }) => {
  return <ReactMarkdown>{content}</ReactMarkdown>;
};

const ChatComponent: React.FC<InputTextProps> = ({
  selectedChatId,
  chats,
  setChats,
  updateChatMessages,
  sendMessageButtonRef,
  onChatLoaded,
  onOpenSettings,

  systemInstructions,
  setSystemInstructions,
  temperature,
  setTemperature,
  maxTokens,
  setMaxTokens,
  topP,
  setTopP,
  stopSequences,
  setStopSequences,
  frequencyPenalty,
  setFrequencyPenalty,
  presencePenalty,
  setPresencePenalty,
}) => {
  useEffect(() => {
    // Notify that the chat component has loaded
    onChatLoaded();
  }, [onChatLoaded]);
  // const [selectedModel, setSelectedModel] = useState("Model");
  // const [selectedCreator, setSelectedCreator] = useState("Creator");
  // const [selectedLLM, setSelectedLLM] = useState("LLM:");
  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate("/welcome-screen");
  };

  // if (selectedChatId === null) {
  //   selectedChatId = chats.length - 1;
  // }
  // selectedChatId = 0;

  const {
    selectedModel,
    setSelectedModel,

    selectedCreator,
    setSelectedCreator,
    selectedLLM,
    setSelectedLLM,
  } = useAppContext();

  const [inputText, setInputText] = useState<string>(() => {
    const savedInputText = sessionStorage.getItem("inputText");
    return savedInputText || "";
  });

  useEffect(() => {
    sessionStorage.setItem("inputText", inputText);
  }, [inputText]);

  const handleTypingComplete = (messageId: number) => {
    const chat = chats.find((chat) => chat.id === selectedChatId);
    if (chat) {
      const updatedMessages = chat.messages.map((msg) =>
        msg.id === messageId ? { ...msg, isNew: false } : msg
      );
      // update chat thread every send message
      updateChatMessages(
        chat.id,
        updatedMessages,
        selectedCreator,
        selectedModel,
        systemInstructions,
        temperature,
        maxTokens,
        stopSequences,
        topP,
        frequencyPenalty,
        presencePenalty
      );
    }
  };

  const chatContainerRef = useRef(null);
  const chatEndRef = useRef(null);
  const [isAutoScrollEnabled, setIsAutoScrollEnabled] = useState(true);
  useEffect(() => {
    chatEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [updateChatMessages, onChatLoaded, updateChatMessages, chats]); // Auto-scroll when messages change

  useEffect(() => {
    const chatContainer = chatContainerRef.current;

    const observer = new MutationObserver(() => {
      if (isAutoScrollEnabled) {
        chatEndRef.current?.scrollIntoView({ behavior: "smooth" });
      }
    });

    // Observe changes in the chat container's child elements
    if (chatContainer) {
      observer.observe(chatContainer, { childList: true, subtree: true });
    }

    // Cleanup observer on unmount
    return () => observer.disconnect();
  }, [chats, updateChatMessages, onChatLoaded, isAutoScrollEnabled]);

  const handleScroll = () => {
    const chatContainer = chatContainerRef.current;
    const { scrollTop, scrollHeight, clientHeight } = chatContainer;

    // If the user scrolls up, disable auto-scroll
    if (scrollTop + clientHeight < scrollHeight) {
      setIsAutoScrollEnabled(false);
    }
    // If the user is at the bottom, enable auto-scroll
    else if (scrollTop + clientHeight === scrollHeight) {
      setIsAutoScrollEnabled(true);
    }
  };

  useEffect(() => {
    const chatContainer = chatContainerRef.current;

    if (chatContainer) {
      chatContainer.addEventListener("scroll", handleScroll);
    }

    // Cleanup scroll listener on unmount
    return () => {
      if (chatContainer) {
        chatContainer.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  const [isDropdownDisabled, setIsDropdownDisabled] = useState(false);

  useEffect(() => {
    const selectedChats = chats.filter((chat) => chat.id === selectedChatId);

    selectedChats.map((chat, index) => {
      if (chat.messages && chat.messages.length > 0) {
        setIsDropdownDisabled(true);
        console.log("Cant change model of ", chat.id);
      } else {
        setIsDropdownDisabled(false);
      }
    });
  }, [selectedChatId, chats]);

  useEffect(() => {
    chats.map((chat) => {
      if (chat.id === selectedChatId) {
        updateChatMessages(
          chat.id,
          null,
          selectedCreator,
          selectedModel,
          systemInstructions,
          temperature,
          maxTokens,
          stopSequences,
          topP,
          frequencyPenalty,
          presencePenalty
        );
      }
    });
  }, [selectedModel]);

  return (
    <div className="chat-container">
      <div className="flex-div flex-div-between">
        <div className="flex-row items-center">
          <Dropdownllm
            selectedModel={selectedModel}
            setSelectedModel={setSelectedModel}
            selectedCreator={selectedCreator}
            setSelectedCreator={setSelectedCreator}
            selectedLLM={selectedLLM}
            setSelectedLLM={setSelectedLLM}
            disabled={isDropdownDisabled}
          />
          <div>
            <Tooltip title="Advanced Settings">
              <IconButton
                color="inherit"
                style={{
                  color: "white",
                  height: "fit-content",
                }}
                // onMouseEnter={onOpenSettings}
                // onMouseLeave={onOpenSettings}
                aria-label="open right drawer"
                onClick={onOpenSettings}
              >
                <SettingsIcon />
              </IconButton>
            </Tooltip>
          </div>
        </div>
      </div>

      <div className="messages-container" ref={chatContainerRef}>
        {selectedChatId !== null &&
          chats
            .find((chat) => chat.id === selectedChatId)
            ?.messages.map((message) => (
              // className={`message ${message.type}`}
              <div key={message.id}>
                <div
                  className={
                    message.role === "user" ? "chat-div-user" : "chat-div"
                  }
                >
                  <div className="avatar">
                    <img
                      src={message.role === "user" ? profpic : logo}
                      alt="avatar"
                      style={{
                        width: "20px",
                        height: "20px",
                        borderRadius: "50%",
                      }}
                    />
                  </div>

                  <div className="content">
                    <div
                      className={
                        message.role === "user" ? "content-2-user" : "content-2"
                      }
                    >
                      {/*More button for usertext */}
                      {/* {message.role === "user" ? (
                        <button className="message-button">
                          <svg
                            className="message-svg"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 448 512"
                          >
                            <path
                              fill="#ffffff"
                              d="M8 256a56 56 0 1 1 112 0A56 56 0 1 1 8 256zm160 0a56 56 0 1 1 112 0 56 56 0 1 1 -112 0zm216-56a56 56 0 1 1 0 112 56 56 0 1 1 0-112z"
                            />
                          </svg>
                        </button>
                      ) : (
                        ""
                      )} */}

                      <div
                        className={
                          message.role === "user"
                            ? "message-text-user"
                            : "message-text"
                        }
                      >
                        {/*loader */}
                        {message.text === "loading" ? (
                          <svg
                            className="svg-rotate"
                            viewBox="0 0 16 16"
                            style={{ margin: "9px 5px" }}
                          >
                            <image href={logo192} className="svg-image" />
                          </svg>
                        ) : (
                          <div
                            className={
                              message.role === "user"
                                ? "message-text-user"
                                : "message-text"
                            }
                          >
                            {
                              message.role === "user" ? (
                                message.text // Regular text for user
                              ) : message.isNew ? ( // Only apply TypeWriter for new AI responses
                                <TypingEffect
                                  text={message.text}
                                  onTypingComplete={() =>
                                    handleTypingComplete(message.id)
                                  }
                                />
                              ) : (
                                <MarkdownRenderer content={message.text} />
                              ) // Display old responses normally
                            }
                          </div>
                        )}
                      </div>
                      {/* {message.role === "user" ? (
                        ""
                      ) : (
                        <IconContainer2 rightOpen={rightOpen}>
                          <button
                            className="message-button"
                            aria-label="open right drawer"
                            onClick={handleRightDrawerToggle}
                          >
                            <svg
                              className="message-svg"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 448 512"
                            >
                              <path
                                fill="#ffffff"
                                d="M352 224c53 0 96-43 96-96s-43-96-96-96s-96 43-96 96c0 4 .2 8 .7 11.9l-94.1 47C145.4 170.2 121.9 160 96 160c-53 0-96 43-96 96s43 96 96 96c25.9 0 49.4-10.2 66.6-26.9l94.1 47c-.5 3.9-.7 7.8-.7 11.9c0 53 43 96 96 96s96-43 96-96s-43-96-96-96c-25.9 0-49.4 10.2-66.6 26.9l-94.1-47c.5-3.9 .7-7.8 .7-11.9s-.2-8-.7-11.9l94.1-47C302.6 213.8 326.1 224 352 224z"
                              />
                            </svg>
                          </button>
                        </IconContainer2>
                      )} */}
                    </div>
                    {/* {message.role === "user" ? (
                      ""
                    ) : (
                      <div>
                        <button className="message-button">
                          <svg
                            className="message-svg"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                          >
                            <path
                              fill="#ffffff"
                              d="M225.8 468.2l-2.5-2.3L48.1 303.2C17.4 274.7 0 234.7 0 192.8v-3.3c0-70.4 50-130.8 119.2-144C158.6 37.9 198.9 47 231 69.6c9 6.4 17.4 13.8 25 22.3c4.2-4.8 8.7-9.2 13.5-13.3c3.7-3.2 7.5-6.2 11.5-9c0 0 0 0 0 0C313.1 47 353.4 37.9 392.8 45.4C462 58.6 512 119.1 512 189.5v3.3c0 41.9-17.4 81.9-48.1 110.4L288.7 465.9l-2.5 2.3c-8.2 7.6-19 11.9-30.2 11.9s-22-4.2-30.2-11.9zM239.1 145c-.4-.3-.7-.7-1-1.1l-17.8-20c0 0-.1-.1-.1-.1c0 0 0 0 0 0c-23.1-25.9-58-37.7-92-31.2C81.6 101.5 48 142.1 48 189.5v3.3c0 28.5 11.9 55.8 32.8 75.2L256 430.7 431.2 268c20.9-19.4 32.8-46.7 32.8-75.2v-3.3c0-47.3-33.6-88-80.1-96.9c-34-6.5-69 5.4-92 31.2c0 0 0 0-.1 .1s0 0-.1 .1l-17.8 20c-.3 .4-.7 .7-1 1.1c-4.5 4.5-10.6 7-16.9 7s-12.4-2.5-16.9-7z"
                            />
                          </svg>
                        </button>
                        <button className="message-button">
                          <svg
                            className="message-svg"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                          >
                            <path
                              fill="#ffffff"
                              d="M323.8 477.2c-38.2 10.9-78.1-11.2-89-49.4l-5.7-20c-3.7-13-10.4-25-19.5-35l-51.3-56.4c-8.9-9.8-8.2-25 1.6-33.9s25-8.2 33.9 1.6l51.3 56.4c14.1 15.5 24.4 34 30.1 54.1l5.7 20c3.6 12.7 16.9 20.1 29.7 16.5s20.1-16.9 16.5-29.7l-5.7-20c-5.7-19.9-14.7-38.7-26.6-55.5c-5.2-7.3-5.8-16.9-1.7-24.9s12.3-13 21.3-13L448 288c8.8 0 16-7.2 16-16c0-6.8-4.3-12.7-10.4-15c-7.4-2.8-13-9-14.9-16.7s.1-15.8 5.3-21.7c2.5-2.8 4-6.5 4-10.6c0-7.8-5.6-14.3-13-15.7c-8.2-1.6-15.1-7.3-18-15.2s-1.6-16.7 3.6-23.3c2.1-2.7 3.4-6.1 3.4-9.9c0-6.7-4.2-12.6-10.2-14.9c-11.5-4.5-17.7-16.9-14.4-28.8c.4-1.3 .6-2.8 .6-4.3c0-8.8-7.2-16-16-16H286.5c-12.6 0-25 3.7-35.5 10.7l-61.7 41.1c-11 7.4-25.9 4.4-33.3-6.7s-4.4-25.9 6.7-33.3l61.7-41.1c18.4-12.3 40-18.8 62.1-18.8H384c34.7 0 62.9 27.6 64 62c14.6 11.7 24 29.7 24 50c0 4.5-.5 8.8-1.3 13c15.4 11.7 25.3 30.2 25.3 51c0 6.5-1 12.8-2.8 18.7C504.8 238.3 512 254.3 512 272c0 35.3-28.6 64-64 64l-92.3 0c4.7 10.4 8.7 21.2 11.8 32.2l5.7 20c10.9 38.2-11.2 78.1-49.4 89zM32 384c-17.7 0-32-14.3-32-32V128c0-17.7 14.3-32 32-32H96c17.7 0 32 14.3 32 32V352c0 17.7-14.3 32-32 32H32z"
                            />
                          </svg>
                        </button>
                        <button className="message-button">
                          <svg
                            className="message-svg"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                          >
                            <path
                              fill="#ffffff"
                              d="M142.9 142.9c62.2-62.2 162.7-62.5 225.3-1L327 183c-6.9 6.9-8.9 17.2-5.2 26.2s12.5 14.8 22.2 14.8H463.5c0 0 0 0 0 0H472c13.3 0 24-10.7 24-24V72c0-9.7-5.8-18.5-14.8-22.2s-19.3-1.7-26.2 5.2L413.4 96.6c-87.6-86.5-228.7-86.2-315.8 1C73.2 122 55.6 150.7 44.8 181.4c-5.9 16.7 2.9 34.9 19.5 40.8s34.9-2.9 40.8-19.5c7.7-21.8 20.2-42.3 37.8-59.8zM16 312v7.6 .7V440c0 9.7 5.8 18.5 14.8 22.2s19.3 1.7 26.2-5.2l41.6-41.6c87.6 86.5 228.7 86.2 315.8-1c24.4-24.4 42.1-53.1 52.9-83.7c5.9-16.7-2.9-34.9-19.5-40.8s-34.9 2.9-40.8 19.5c-7.7 21.8-20.2 42.3-37.8 59.8c-62.2 62.2-162.7 62.5-225.3 1L185 329c6.9-6.9 8.9-17.2 5.2-26.2s-12.5-14.8-22.2-14.8H48.4h-.7H40c-13.3 0-24 10.7-24 24z"
                            />
                          </svg>
                        </button>
                        <button className="message-button">
                          <svg
                            className="message-svg"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 448 512"
                          >
                            <path
                              fill="#ffffff"
                              d="M384 336H192c-8.8 0-16-7.2-16-16V64c0-8.8 7.2-16 16-16l140.1 0L400 115.9V320c0 8.8-7.2 16-16 16zM192 384H384c35.3 0 64-28.7 64-64V115.9c0-12.7-5.1-24.9-14.1-33.9L366.1 14.1c-9-9-21.2-14.1-33.9-14.1H192c-35.3 0-64 28.7-64 64V320c0 35.3 28.7 64 64 64zM64 128c-35.3 0-64 28.7-64 64V448c0 35.3 28.7 64 64 64H256c35.3 0 64-28.7 64-64V416H272v32c0 8.8-7.2 16-16 16H64c-8.8 0-16-7.2-16-16V192c0-8.8 7.2-16 16-16H96V128H64z"
                            />
                          </svg>
                        </button>
                      </div>
                    )} */}
                  </div>
                </div>
              </div>
            ))}

        <div ref={chatEndRef} />
      </div>

      <InputText
        inputText={inputText}
        setInputText={setInputText}
        selectedCreator={selectedCreator}
        selectedChatId={selectedChatId}
        chats={chats}
        updateChatMessages={updateChatMessages}
        selectedModel={selectedModel}
        setSelectedModel={setSelectedModel}
        sendMessageButtonRef={sendMessageButtonRef}
        //
        systemInstructions={systemInstructions}
        setSystemInstructions={setSystemInstructions}
        temperature={temperature}
        setTemperature={setTemperature}
        maxTokens={maxTokens}
        setMaxTokens={setMaxTokens}
        topP={topP}
        setTopP={setTopP}
        stopSequences={stopSequences}
        setStopSequences={setStopSequences}
        frequencyPenalty={frequencyPenalty}
        setFrequencyPenalty={setFrequencyPenalty}
        presencePenalty={presencePenalty}
        setPresencePenalty={setPresencePenalty}
        disabled={isDropdownDisabled}
      />
    </div>
  );
};
export default ChatComponent;
export { MarkdownRenderer };
