// src/screens/MainScreen.tsx
import React, { useCallback, useEffect, useRef, useState } from "react";
import NavigationBar from "./Navigationbar";
import WelcomeSection from "./WelcomeSection";
import SuggestedTopics from "./SuggestedTopics";
import AppFooter from "./Footer";
import "src/styles.css";
import { styled } from "@mui/system";
import { Container, CssBaseline, IconButton } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import SettingsIcon from "@mui/icons-material/Settings";
import {
  CloseButton,
  IconContainer1,
  IconContainer2,
  MainContent,
  Root,
  SideDrawer,
  TopAppBar,
} from "../components/ChatScreenWindow";
import Sidebar from "../components/SideBar";
import { ChatHistory, Message } from "../components/SendMessage";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { useAuth } from "../components/AuthContext";
import SettingsPanel from "../components/SettingsPanel";
import { useAppContext } from "../components/LLMContext";

const ContentContainer = styled("div")<{
  leftOpen: boolean;
  rightOpen: boolean;
}>(({ leftOpen, rightOpen }) => ({
  // display: "flex",
  // flexGrow: 1,
  margin: "70px 20px 0 20px", // Adjust based on the height of both app bars
  transition:
    "margin-left 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms, margin-right 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms",

  backgroundColor: "#1f1f1f",
}));

const WelcomeScreen: React.FC = () => {
  const {
    selectedModel,
    setSelectedModel,
    selectedCreator,
    setSelectedCreator,
    selectedLLM,
    setSelectedLLM,
  } = useAppContext();

  const { session } = useAuth();
  const user_id = session?.user?.user_metadata.user_id;
  const navigate = useNavigate();

  useEffect(() => {
    if (session !== "loading" && !session?.user) {
      navigate("/login");
    }
  }, [session]);
  const [userSubstype, setUserSubstype] = useState<number>(0);
  const [isNewUser, setIsNewUser] = useState<boolean>(false);

  const newUserPromo = async (type: string) => {
    const urlEnv = process.env.REACT_APP_SERVER_ACCESS;
    console.log("user_id", user_id);
    const response = await axios.post(`${urlEnv}create-checkout-session`, {
      product_id: "price_1QAYGTISnPjRR9tdIiMEiKwR",
      user_id: user_id,
      type: type,
      promo: true,
    });
    console.log(response);
    window.location.href = response.data.url;

    localStorage.setItem("promoApplied", "true");
  };
  const [openModalPromo, setOpenModalPromo] = useState(false);

  useEffect(() => {
    const urlEnv = process.env.REACT_APP_SERVER_ACCESS;

    const checkIfNewUserAndSubscriptionType = async () => {
      try {
        const response = await axios.get(
          `${urlEnv}checkIfNewUserAndSubscriptionType`,
          {
            params: { user_id: user_id },
          }
        );
        console.log("checkifnewuser", response.data);

        if (response.data.subscription) {
          setUserSubstype(response.data.subscription);
          console.log("set user subs type", response.data.subscription);
        } else {
          //setUserSubstype("");
        }

        if (response.data.success) {
          setIsNewUser(true);
          const promoApplied = localStorage.getItem("promoApplied");
          if (!promoApplied) {
            newUserPromo("lifetime");
          }
          console.log("set user role type");
        } else {
          setIsNewUser(false);
          console.log("yayaya");
        }
      } catch (err) {
        console.error("error fething checkIfNewUser:", err.message);
      }
    };
    checkIfNewUserAndSubscriptionType();
  }, [openModalPromo]);

  // const hasOpenedModal = useRef(false);

  useEffect(() => {
    if (isNewUser) {
      // Function to handle modal opening
      const handleOpenModal = () => {
        setOpenModalPromo(true);
        // Set the current timestamp in localStorage when modal is shown
        localStorage.setItem("lastModalShown", Date.now().toString());
      };

      // Check if the user is new or if an hour has passed since the modal was last shown
      const lastModalShown = localStorage.getItem("lastModalShown");
      const oneHourInMillis = 3600000; // 1 hour in milliseconds
      const now = Date.now();

      // Show modal on first visit or if more than 1 hour has passed since last modal
      if (!lastModalShown || now - parseInt(lastModalShown) > oneHourInMillis) {
        handleOpenModal();
      }

      // Set an interval to show the modal every 1 hour
      const intervalId = setInterval(() => {
        handleOpenModal();
      }, oneHourInMillis);

      // Cleanup interval on component unmount
      return () => {
        clearInterval(intervalId);
      };
    }
  }, [isNewUser]);

  const [leftOpen, setLeftOpen] = React.useState(false);
  const [rightOpen, setRightOpen] = React.useState(false);
  const [isDeletingChats, setIsDeletingChats] = useState(false);

  const [chats, setChats] = useState<ChatHistory[]>(() => {
    const savedChats = localStorage.getItem("chats");
    return savedChats ? JSON.parse(savedChats) : [];
  });
  useEffect(() => {
    localStorage.setItem("chats", JSON.stringify(chats));
  }, [chats]);

  const [selectedChatId, setSelectedChatId] = useState<number | null>(null);
  const initialLoad = useRef(true);
  const location = useLocation();
  const fromWelcomeScreen = location.state?.fromWelcomeScreen;

  // useEffect(() => {
  //   const chatExists = chats.length > 0;
  //   const chatInitialized =
  //     sessionStorage.getItem("chatInitialized") === "true";
  //   if (
  //     !fromWelcomeScreen &&
  //     initialLoad.current &&
  //     !chatExists &&
  //     !chatInitialized
  //   ) {
  //     initialLoad.current = false;
  //     // Find the maximum existing ID
  //     const maxId = chats.reduce((max, chat) => Math.max(max, chat.id), -0);
  //     const newChat: ChatHistory = {
  //       // thread_id: 263,
  //       id: maxId + 1,
  //       title: `Chat ${maxId + 1}`,
  //       messages: [
  //         // {
  //         //   id: 0,
  //         //   text: "Hello! How can I assist you today?",
  //         //   role: "assistant",
  //         // },
  //       ],
  //       creator: selectedCreator,
  //       model: selectedModel,
  //     };
  //     setChats((prevChats) => [...prevChats, newChat]);
  //     setSelectedChatId(newChat.id);
  //     sessionStorage.setItem("chatInitialized", "true");
  //   }
  // }, []);

  const handleLeftDrawerToggle = () => {
    setLeftOpen(!leftOpen);
  };

  const handleRightDrawerToggle = () => {
    setRightOpen(!rightOpen);
  };

  const handleNewChat = useCallback(() => {
    // Find the maximum existing ID
    const maxId = chats.reduce((max, chat) => Math.max(max, chat.id), -0);

    const newChat: ChatHistory = {
      // thread_id: 263,
      id: maxId + 1,
      title: `Chat ${maxId + 1}`,
      messages: [],
      creator: selectedCreator,
      model: selectedModel,
      systemInstructions: systemInstructions,
      temperature: temperature,
      maxTokens: maxTokens,
      stopSequences: stopSequences,
      //set to optional for Anthropic creator
      topP: topP,
      frequencyPenalty: frequencyPenalty,
      presencePenalty: presencePenalty,
    };
    setChats((prevChats) => [...prevChats, newChat]);
    setSelectedChatId(newChat.id);
    console.log("yawa nga chat sa welcome screen", chats);
  }, [chats.length]);

  const removeAllItems = () => {
    sessionStorage.removeItem("systemInstructions");
    sessionStorage.removeItem("temperature");
    sessionStorage.removeItem("maxTokens");
    sessionStorage.removeItem("topP");
    sessionStorage.removeItem("stopSequences");
    sessionStorage.removeItem("frequencyPenalty");
    sessionStorage.removeItem("presencePenalty");
  };
  const deleteChat = async (chatId: number) => {
    removeAllItems();
    // console.log("chatId", chatId);
    setIsDeletingChats(true);

    const threadChatId = await fetchChatThreadID(chatId, user_id);
    // console.log("chatThreadIDchatThreadID", threadChatId);

    if (threadChatId) {
      const urlEnv = process.env.REACT_APP_SERVER_ACCESS;
      // console.log("urlEnv", urlEnv);
      try {
        const response = await axios.post(`${urlEnv}delete-chat-db`, {
          chatId: chatId,
          chatThreadID: threadChatId,
          user_id: user_id,
        });
        if (response) {
          // console.log(response.data);
        } else {
          // console.log("chat from DB deleted");
        }
      } catch (error) {
        console.log(error);
      }
    }

    const updatedChats = chats.filter((chat) => chat.id !== chatId);
    setChats(updatedChats);
    console.log("chatIdchatIdchatId", chatId);
    if (selectedChatId === chatId) {
      setSelectedChatId(
        updatedChats.length > 0
          ? updatedChats[updatedChats.length - 1].id
          : null
      );
    }

    setIsDeletingChats(false);
  };
  const fetchChatThreadID = async (chat_id, user_id) => {
    const urlEnv = process.env.REACT_APP_SERVER_ACCESS + "getThreadId";

    const response = await axios.get(urlEnv, {
      params: {
        chat_id: chat_id,
        user_id: user_id,
      },
    });

    console.log(response.data.thread_id);

    if (response.data) {
      return response.data.thread_id;
    } else if (!response.data) {
      return undefined;
    }
  };

  const handleSelectChat = (chatId: number) => {
    setSelectedChatId(chatId);
    console.log("chat.id", chatId);
    chats.filter((chat) => {
      if (chat.id === chatId) {
        setSelectedModel(chat.model);
        setSelectedCreator(chat.creator);
        setSystemInstructions(chat.systemInstructions);
        setTemperature(chat.temperature);
        setMaxTokens(chat.maxTokens);
        setStopSequences(chat.stopSequences);
        setTopP(chat.topP);
        setFrequencyPenalty(chat.frequencyPenalty);
        setPresencePenalty(chat.presencePenalty);
      }
    });
  };
  const [systemInstructions, setSystemInstructions] = useState<string>(() => {
    const savedSystemInstructions =
      sessionStorage.getItem("systemInstructions");
    return savedSystemInstructions || "";
  });
  const [temperature, setTemperature] = useState<number>(() => {
    const savedTemperature = sessionStorage.getItem("temperature");
    return savedTemperature ? parseFloat(savedTemperature) : 1;
  });
  const [maxTokens, setMaxTokens] = useState<number>(() => {
    const savedMaxTokens = sessionStorage.getItem("maxTokens");
    return savedMaxTokens ? parseInt(savedMaxTokens) : 100;
  });
  const [topP, setTopP] = useState<number>(() => {
    const savedTopP = sessionStorage.getItem("topP");
    return savedTopP !== null ? parseFloat(savedTopP) : 1;
  });

  const [stopSequences, setStopSequences] = useState<string[]>(() => {
    const savedStopSequences = sessionStorage.getItem("stopSequences");
    return savedStopSequences ? JSON.parse(savedStopSequences) : [];
  });

  const [frequencyPenalty, setFrequencyPenalty] = useState<number>(() => {
    const savedFrequencyPenalty = sessionStorage.getItem("frequencyPenalty");
    return savedFrequencyPenalty !== null
      ? parseFloat(savedFrequencyPenalty)
      : 0;
  });

  const [presencePenalty, setPresencePenalty] = useState<number>(() => {
    const savedPresencePenalty = sessionStorage.getItem("presencePenalty");
    return savedPresencePenalty !== null ? parseFloat(savedPresencePenalty) : 0;
  });

  useEffect(() => {
    sessionStorage.setItem("systemInstructions", systemInstructions);
  }, [systemInstructions]);

  useEffect(() => {
    sessionStorage.setItem("temperature", temperature.toString());
  }, [temperature]);

  useEffect(() => {
    sessionStorage.setItem("maxTokens", maxTokens.toString());
  }, [maxTokens]);

  useEffect(() => {
    sessionStorage.setItem("topP", topP.toString());
  }, [topP]);

  useEffect(() => {
    sessionStorage.setItem("frequencyPenalty", frequencyPenalty.toString());
  }, [frequencyPenalty]);

  useEffect(() => {
    sessionStorage.setItem("presencePenalty", presencePenalty.toString());
  }, [presencePenalty]);

  useEffect(() => {
    sessionStorage.setItem("selectedModel", selectedModel);
    sessionStorage.setItem("selectedCreator", selectedCreator);
  }, [selectedModel, selectedCreator]);

  const [hasSentInitialMessage, setHasSentInitialMessage] = useState(() => {
    return sessionStorage.getItem("hasSentInitialMessage") === "true";
  });

  const [isChatLoaded, setIsChatLoaded] = useState(false);
  const sendMessageButtonRef = useRef<HTMLButtonElement>(null);
  useEffect(() => {
    if (
      !hasSentInitialMessage &&
      fromWelcomeScreen &&
      isChatLoaded &&
      sendMessageButtonRef.current
    ) {
      sendMessageButtonRef.current.click();

      sessionStorage.setItem("hasSentInitialMessage", "true");
    }
  }, [fromWelcomeScreen, isChatLoaded]);

  const [isDropdownDisabled, setIsDropdownDisabled] = useState(false);

  const isWelcomeScreen = location.pathname === "/welcome-screen";

  useEffect(() => {
    const selectedChats = chats.filter((chat) => chat.id === selectedChatId);

    selectedChats.map((chat, index) => {
      if (chat.messages && chat.messages.length > 0) {
        setIsDropdownDisabled(true);
      } else {
        setIsDropdownDisabled(false);
      }

      isWelcomeScreen && setIsDropdownDisabled(false);
    });
  }, [selectedChatId, chats]);

  const updateChatMessages = (
    chatId: number,
    messages: Message[],
    creator: string,
    model: string,
    systemInstructions: string,
    temperature: number,
    maxTokens: number,
    stopSequences: string[],
    topP?: number,
    frequencyPenalty?: number,
    presencePenalty?: number
  ) => {
    const updatedChats = chats.map((chat) => {
      // console.log("yachatId", chatId);
      // console.log("yamessages", messages);
      // console.log("yacreator", creator);
      // console.log("yamodel", model);
      // console.log("yasystemInstructions", systemInstructions);
      // console.log("yatemperature", temperature);
      // console.log("yamaxTokens", maxTokens);
      // console.log("yastopSequences", stopSequences);
      // console.log("yatopP", topP);
      // console.log("yafrequencyPenalty", frequencyPenalty);
      // console.log("yapresencePenalty", presencePenalty);

      if (chat.id === chatId) {
        return {
          ...chat,
          messages: messages === null ? [...chat.messages] : messages,
          creator,
          model,
          systemInstructions,
          temperature,
          maxTokens,
          stopSequences,
          topP,
          frequencyPenalty,
          presencePenalty,
        };
      }
      return chat;
    });
    setChats(updatedChats);
  };

  const [data, setData] = useState(null);

  const [loading, setLoading] = useState(true);
  const fetchData = async () => {
    setLoading(true);
    const urlEnv = process.env.REACT_APP_SERVER_ACCESS;
    try {
      const response = await axios.post(`${urlEnv}fetch-credits-history`, {
        user_id: user_id,
      });
      console.log("responseasdasdsad", response.data);
      setData(response.data);
    } catch (err) {
      console.log(err.message || "An error occurred");
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, [chats, user_id]);

  useEffect(() => {
    fetchData();
    setSelectedCreator("OpenAI");
    setSelectedModel("gpt-4o-mini");
  }, []);

  useEffect(() => {
    chats.map((chat) => {
      if (chat.id === selectedChatId) {
        updateChatMessages(
          chat.id,
          null,
          selectedCreator,
          selectedModel,
          systemInstructions,
          temperature,
          maxTokens,
          stopSequences,
          topP,
          frequencyPenalty,
          presencePenalty
        );
      }
    });
  }, [selectedModel]);

  const [userChatData, setUserChatData] = useState<[]>(null);
  const [loadingChatData, setLoadingChatData] = useState(true);
  useEffect(() => {
    if (!loadingChatData) console.log("userChatData", userChatData.length);
  }, [userChatData]);

  const fetchUserChatData = async () => {
    setLoadingChatData(true);
    console.log("calling_fetchUserChatData");
    const urlEnv = process.env.REACT_APP_SERVER_ACCESS;
    try {
      const response = await axios.get(`${urlEnv}fetch-user-chats`, {
        params: { user_id: user_id },
      });
      if (response.data) {
        setUserChatData(response.data);
        console.log("responsedata123", response.data);
      }
      setLoadingChatData(false);
    } catch (error) {
      console.error("error fetching userdata:", error);
      setLoadingChatData(false);
    }
    setLoadingChatData(false);
  };
  useEffect(() => {
    if (user_id) {
      fetchUserChatData();
    }
  }, [user_id]);

  return (
    <>
      <Root>
        <CssBaseline />
        <TopAppBar position="fixed">
          <NavigationBar data={data} loading={loading} />
          {/* Include NavigationBar inside the top app bar */}
        </TopAppBar>
        {!loadingChatData && userChatData.length > 0 && (
          <IconContainer1 leftOpen={leftOpen}>
            {!leftOpen ? (
              <IconButton
                color="inherit"
                aria-label="open left drawer"
                onClick={handleLeftDrawerToggle}
              >
                {!leftOpen ? <MenuIcon /> : ""}
              </IconButton>
            ) : (
              ""
            )}
          </IconContainer1>
        )}

        {/* <IconContainer2 rightOpen={rightOpen}>
          {!rightOpen ? (
            <IconButton
              color="inherit"
              aria-label="open right drawer"
              onClick={handleRightDrawerToggle}
            >
              {!rightOpen ? <SettingsIcon /> : ""}
              <SettingsIcon />
            </IconButton>
          ) : (
            ""
          )}
        </IconContainer2> */}

        <ContentContainer leftOpen={leftOpen} rightOpen={rightOpen}>
          <SideDrawer variant="persistent" anchor="left" open={leftOpen}>
            <CloseButton
              handler={handleLeftDrawerToggle}
              rightORleft={"close-left"}
            />
            <Sidebar
              chats={chats}
              activeChatId={selectedChatId}
              startNewChat={handleNewChat}
              loadChat={handleSelectChat}
              deleteChat={deleteChat}
              isNewUser={isNewUser}
              userSubstype={userSubstype}
              openModalPromo={isNewUser ? openModalPromo : undefined}
              setOpenModalPromo={setOpenModalPromo}
            />
          </SideDrawer>

          <MainContent leftOpen={leftOpen} rightOpen={rightOpen}>
            <WelcomeSection onOpenSettings={handleRightDrawerToggle} />
          </MainContent>

          <SideDrawer variant="persistent" anchor="right" open={rightOpen}>
            <CloseButton
              handler={handleRightDrawerToggle}
              rightORleft={"close-right"}
            />
            <SettingsPanel
              // onSettingsChange={handleSettingsChange}
              systemInstructions={systemInstructions}
              setSystemInstructions={setSystemInstructions}
              temperature={temperature}
              setTemperature={setTemperature}
              maxTokens={maxTokens}
              setMaxTokens={setMaxTokens}
              topP={topP}
              setTopP={setTopP}
              stopSequences={stopSequences}
              setStopSequences={setStopSequences}
              frequencyPenalty={frequencyPenalty}
              setFrequencyPenalty={setFrequencyPenalty}
              presencePenalty={presencePenalty}
              setPresencePenalty={setPresencePenalty}
              disabled={isDropdownDisabled}
              startNewChat={handleNewChat}
            />
          </SideDrawer>
        </ContentContainer>
        <AppFooter />
      </Root>
    </>
  );
};

export default WelcomeScreen;
