import React, { createContext, useContext, useEffect, useState } from "react";
import "src/styles.css";
import NavigationBar from "../screens/Navigationbar";
import WelcomeSection from "../screens/WelcomeSection";
import AppFooter from "../screens/Footer";
import logo from "../assets/logo.png";
import { styled } from "@mui/system";
import eyeopen from "src/assets/eye-regular.svg";
import eyeclose from "src/assets/eye-slash-regular.svg";
import {
  Containerdiv,
  Formdiv,
  Titlediv,
  Labeldiv,
  Inputdiv,
  Linkdiv,
  Buttondiv,
  Footerdiv,
  PasswordContainerdiv,
  ToggleButtondiv,
} from "./Signup";
import errorimage from "src/assets/circle-exclamation-solid.svg";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { createClient } from "@supabase/supabase-js";
import { useAuth } from "./AuthContext";
import { supabase } from "./SupabaseClient";
// import { useSession } from "./SessionProvider";

import * as Dialog from "@radix-ui/react-dialog";
import crossicon from "src/assets/cross-2.svg";

export const ErrorImage = () => {
  return <img src={errorimage} alt="error" className="error-image" />;
};

interface LoginForm {
  email: string;
  password: string;
}

const Login: React.FC = () => {
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const { session, setSession } = useAuth();
  useEffect(() => {
    if (session !== "loading" && session?.user) {
      navigate("/welcome");
    }
  }, [session]);

  const [form, setForm] = useState<LoginForm>({ email: "", password: "" });
  const [errors, setErrors] = useState<LoginForm>({ email: "", password: "" });
  const [loginErrors, setLoginErrors] = useState("");

  const validateEmail = (email: string): string => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!email) {
      return "Email address is required";
    }
    if (!re.test(email)) {
      return "Invalid email address";
    }

    return undefined;
  };

  const validatePassword = (password: string): string => {
    if (!password) {
      return "Password is required";
    }

    return undefined;
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };

  const validateCredentials = async (email: string) => {
    const urlEnv = process.env.REACT_APP_SERVER_ACCESS + "checkcredentials";
    console.log("url", urlEnv);
    console.log("email", email);
    try {
      const response = await axios.post(urlEnv, {
        //chatId: selectedChatId,
        email: email,
      });

      // console.log("console.data", response.status);
      // console.log(" response console.data", response);
      if (!response.data) {
        return "Invalid credentials";
      }
      return undefined;
      //alert("data is saved");
    } catch (error) {
      if (error.response) {
        console.error("Error saving query:", error.response.data);
      } else if (error.request) {
        // The request was made but no response was received
        console.error("No response received:", error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.error("Error setting up request:", error.message);
      }
    }
  };
  let [tof, setTof] = useState(false);
  const [isOpen1, setIsOpen1] = useState(false);

  const openDialog1 = () => setIsOpen1(true);
  const closeDialog1 = () => setIsOpen1(false);
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const emailError = validateEmail(form.email);
    const passwordError = validatePassword(form.password);
    const credentialsError = await validateCredentials(form.email);

    if (credentialsError) {
      setErrors({ email: credentialsError, password: credentialsError });
      setTof(true);
    } else if (emailError || passwordError) {
      setErrors({ email: emailError, password: passwordError });
    } else {
      setErrors({ email: undefined, password: undefined });
      try {
        // console.log("url", urlEnv);
        // console.log("email", form.email);
        // console.log("password", form.password);
        try {
          let { data, error } = await supabase.auth.signInWithPassword({
            email: form.email,
            password: form.password,
          });

          // console.log("response.data.session", data.session);
          if (error) {
            //if email not verified
            //resend email verification here
            // console.log("error", error.message);
            setLoginErrors(error.message);
            return;
            openDialog1();
          }
          if (!data.session) {
            //if wrong credentials
            console.log("invalid");
          }

          if (data.session) {
            const response = await axios.post(
              `${process.env.REACT_APP_SERVER_ACCESS}check-paid-session`,
              { email: data.session.user.email }
            );

            // console.log(response.data);
            // console.log(data.session);
            if (response.data) {
              data.session.user.user_metadata = {
                ...data.session.user.user_metadata,
                paid_session: true,
              };
            } else if (!response) {
              data.session.user.user_metadata = {
                ...data.session.user.user_metadata,
                paid_session: false,
              };
            }

            // console.log(data.session);
            setSession(data.session);
            // localStorage.setItem(
            //   "supabaseSession",
            //   JSON.stringify(data.session)
            // );
            // navigate("/welcome");
          }

          //alert("data is saved");
        } catch (error) {
          if (error.response) {
            console.error("Error saving query:", error.response.data);
          } else if (error.request) {
            //
            // The request was made but no response was received
            console.error("No response received:", error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.error("Error setting up request:", error.message);
          }
        }
      } catch (error) {
        console.error("Login failed:", error);
        // Handle login error
      }
    }
  };

  // console.log("111111111111111", session);

  const [isOpen, setIsOpen] = useState(false);

  const openDialog = () => setIsOpen(true);
  const closeDialog = () => setIsOpen(false);

  const handleForgotPassword = async () => {
    const emailError = validateEmail(form.email);
    console.log("form.email", form.email);
    const email = form.email;

    if (emailError) {
      setErrors({ email: emailError, password: undefined });
    } else {
      setErrors({ email: undefined, password: undefined });

      const urlEnv = process.env.REACT_APP_SERVER_ACCESS + "reset-password";

      const response = await axios.post(urlEnv, { email: email });
      console.log(response.data);
      if (response.data.message) {
        openDialog();
      } else if (!response.data) {
        alert(
          "supabase error: Email rate limit exceeded / email confirmation already sent"
        );
      }
    }
  };
  const handleDialogClose = () => {
    // navigate("/login");
  };

  return (
    <>
      <div className="sign-in-up">
        <NavigationBar />
      </div>

      <div className="content-signup-login">
        <div className="signup-login">
          <div className="welcome-section2">
            <h1>Welcome to Panels AI</h1>&nbsp;
            <img src={logo} alt="Logo" className="welcome-logo" />
          </div>
        </div>

        <Containerdiv>
          <Formdiv onSubmit={handleSubmit}>
            <Titlediv>Login to your account</Titlediv>
            <div className="flex-div-column">
              {errors.email && errors.password && (
                <label className="error">
                  <ErrorImage />
                  {errors.email}
                </label>
              )}
              {loginErrors && (
                <label className="error">
                  <ErrorImage />
                  {loginErrors}
                </label>
              )}
              <Labeldiv htmlFor="email">Email</Labeldiv>
              <Inputdiv
                className={errors.email || loginErrors ? "input-error" : ""}
                type="text"
                id="email"
                name="email"
                value={form.email}
                onChange={handleChange}
                placeholder="Enter your email"
              />
              {!tof && errors.email && (
                <label className="error">
                  <ErrorImage />
                  {errors.email}
                </label>
              )}
            </div>
            <PasswordContainerdiv>
              <Labeldiv htmlFor="password" style={{ marginTop: "12px" }}>
                Password
                <Linkdiv href="#" onClick={handleForgotPassword}>
                  Forgot ?
                </Linkdiv>
              </Labeldiv>
              <div
                className={`password-input ${
                  errors.password || loginErrors ? "input-error" : ""
                }`}
              >
                <Inputdiv
                  type={showPassword ? "text" : "password"}
                  id="password"
                  name="password"
                  value={form.password}
                  onChange={handleChange}
                  placeholder="Enter your password"
                />

                <ToggleButtondiv
                  type="button"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  <img
                    src={showPassword ? eyeopen : eyeclose}
                    alt="toggle visibility"
                  />
                </ToggleButtondiv>
              </div>
              {!tof && errors.password && (
                <label className="error">
                  <ErrorImage />
                  {errors.password}
                </label>
              )}
            </PasswordContainerdiv>
            <Buttondiv type="submit">Log in now</Buttondiv>
            {/* <button onClick={test}>test login</button> */}
            <Footerdiv>
              Don't Have An Account ? <Linkdiv href="/signup">Sign up</Linkdiv>
            </Footerdiv>
          </Formdiv>
        </Containerdiv>
      </div>
      <div className="login">
        <AppFooter />
      </div>
      <Dialog.Root
        open={isOpen}
        onOpenChange={(open) => {
          if (!open) {
            handleDialogClose();
          }
          setIsOpen(open);
        }}
      >
        <Dialog.Trigger asChild></Dialog.Trigger>
        <Dialog.Portal>
          <div className="overlay"></div>
          <Dialog.Overlay className="DialogOverlay" />
          <Dialog.Content className="DialogContent DialogCustomWidth-signup">
            <Dialog.Title className="DialogTitle"></Dialog.Title>
            <Dialog.Description className="DialogDescription"></Dialog.Description>

            <div className="subscription-panel">
              <h2>Password reset link sent</h2>
              <p className="description">
                Please check your email to reset your password.
              </p>
            </div>

            <div
              style={{
                display: "flex",
                marginTop: 25,
                justifyContent: "flex-end",
              }}
            >
              <Dialog.Close asChild></Dialog.Close>
            </div>
            <Dialog.Close asChild>
              <button className="IconButton" aria-label="Close">
                <img
                  src={crossicon}
                  alt="icon"
                  style={{
                    width: "20px",
                    height: "20px",

                    filter: "invert(1)",
                  }}
                />
              </button>
            </Dialog.Close>
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root>

      <Dialog.Root
        open={isOpen1}
        onOpenChange={(open) => {
          if (!open) {
            handleDialogClose();
          }
          setIsOpen1(open);
        }}
      >
        <Dialog.Trigger asChild></Dialog.Trigger>
        <Dialog.Portal>
          <div className="overlay"></div>
          <Dialog.Overlay className="DialogOverlay" />
          <Dialog.Content className="DialogContent DialogCustomWidth-signup">
            <Dialog.Title className="DialogTitle"></Dialog.Title>
            <Dialog.Description className="DialogDescription"></Dialog.Description>

            <div className="subscription-panel">
              <h2>Email not verified</h2>
              <p className="description">
                Please check your email to verify to start using PanelsAI.
              </p>
            </div>

            <div
              style={{
                display: "flex",
                marginTop: 25,
                justifyContent: "flex-end",
              }}
            >
              <Dialog.Close asChild></Dialog.Close>
            </div>
            <Dialog.Close asChild>
              <button className="IconButton" aria-label="Close">
                <img
                  src={crossicon}
                  alt="icon"
                  style={{
                    width: "20px",
                    height: "20px",

                    filter: "invert(1)",
                  }}
                />
              </button>
            </Dialog.Close>
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root>
    </>
  );
};

export default Login;
